import React from "react";
import { Button } from "../../Component";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetFourthLevelCategories,
  fetchGetSecondLevelCategories,
  fetchGetThirdLevelCategories,
} from "../../Redux/Category/action";

const CategoryDetail = ({
  setToggle,
  secCat,
  setSecCat,
  firstCat,
  thriCat,
  setThriCat,
  frthCat,
  setFrthCat,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const secCategory = useSelector(
    (state) => state.categories.secCategories,
    shallowEqual
  );

  const thridCategory = useSelector(
    (state) => state.categories.thirdCategories,
    shallowEqual
  );

  const fourthCategory = useSelector(
    (state) => state.categories.fourthCategories,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetSecondLevelCategories(firstCat.id));
  }, []);

  return (
    <div className="w-full">
      <h6 className="font-semibold text-2xl">
        Choose <span className="text-primary">Category</span>
      </h6>
      <p className="text-base mt-2">
        Please select the appropriate sub catgory options based upon the ad you
        want to post
      </p>

      <div className="border w-full  bg-white rounded-xl mt-10">
        <p className="font-semibold text-lg p-5">{firstCat.name}</p>
        <hr className="w-full" />
        <div className="p-5 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
          {secCategory &&
            secCategory.map((val) => {
              return (
                <div
                  key={val.cat_id}
                  onClick={() => {
                    setSecCat({
                      id: val.cat_id,
                      name: val.cat_name,
                    });
                    dispatch(fetchGetThirdLevelCategories(val.cat_id));
                  }}
                  className={`${
                    secCat.id === val.cat_id
                      ? "border-primary bg-green-100"
                      : ""
                  } border rounded-lg mt-3 text-center py-3 cursor-pointer hover:border-primary`}
                >
                  <p>{val.cat_name}</p>
                </div>
              );
            })}
        </div>
      </div>

      {thridCategory && thridCategory.length ? (
        <div className="border w-full  bg-white rounded-xl mt-10">
          <p className="font-semibold text-lg p-5">{secCat.name}</p>
          <hr className="w-full" />
          <div className="p-5 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
            {thridCategory &&
              thridCategory.map((val) => {
                return (
                  <div
                    key={val.cat_id}
                    onClick={() => {
                      setThriCat({
                        id: val.cat_id,
                        name: val.cat_name,
                      });

                      dispatch(fetchGetFourthLevelCategories(val.cat_id));
                    }}
                    className={`${
                      thriCat.id === val.cat_id
                        ? "border-primary bg-green-100"
                        : ""
                    } border rounded-lg mt-3 text-center py-3 cursor-pointer hover:border-primary`}
                  >
                    <p>{val.cat_name}</p>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}

      {fourthCategory && fourthCategory.length ? (
        <div className="border w-full  bg-white rounded-xl mt-10">
          <p className="font-semibold text-lg p-5">{thriCat.name}</p>
          <hr className="w-full" />
          <div className="p-5 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
            {fourthCategory &&
              fourthCategory.map((val) => {
                return (
                  <div
                    key={val.cat_id}
                    onClick={() => {
                      setFrthCat({
                        id: val.cat_id,
                        name: val.cat_name,
                      });
                    }}
                    className={`${
                      frthCat.id === val.cat_id
                        ? "border-primary bg-green-100"
                        : ""
                    } border rounded-lg mt-3 text-center py-3 cursor-pointer hover:border-primary`}
                  >
                    <p>{val.cat_name}</p>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}

      <div className="w-full border mt-7 bg-white rounded-xl p-5 flex items-center gap-x-5 md:gap-x-0 md:justify-between">
        <div
          className="border rounded-xl py-3 px-7 w-52 flex items-center gap-x-2 justify-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <IoIosArrowRoundBack />
          <p>Back</p>
        </div>
        <Button
          value="Next"
          disable={secCat.id ? false : true}
          handleClick={() => {
            setToggle({
              category: false,
              detail: false,
              form: true,
            });
            scrollToTop();
          }}
          className="border rounded-xl py-3 px-7 w-52 bg-primary text-white"
        />
      </div>
    </div>
  );
};

export default CategoryDetail;
