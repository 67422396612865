import React, { useEffect, useState } from "react";
import {
  AdsCard,
  Categories,
  GridAdsCard,
  GridCategories,
  Head,
  HeroSection,
  Loading,
  Newsletter,
  ResgisterAds,
  Signup,
  Title,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAds, removeAdsData } from "../../Redux/Ads/action";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  fetchGetJobsCategories,
  removeJobsData,
} from "../../Redux/Jobs/action";
import {
  fetchAddtoWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { jobsHome } from "../../Assets/Assest";
import { fetchGetAllCategories } from "../../Redux/Category/action";

const Jobs = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [category, setCategory] = useState("");
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("");
  const [searchData, setSearchData] = useState([]);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.ads.isloading, shallowEqual);
  const jobsLoading = useSelector(
    (state) => state.jobs.isloading,
    shallowEqual
  );
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const ads = useSelector((state) => state.ads.ads, shallowEqual);
  const categories = useSelector(
    (state) => state.jobs.categories,
    shallowEqual
  );

  const filterData = useSelector((state) => state.ads.filters, shallowEqual);
  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddtoWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  const handleSearch = () => {
    const data = searchData.filter((elem) => elem.label !== "type");

    if (data.length) {
      localStorage.setItem("search-data", JSON.stringify(data));
    }
    navigate(
      `/search-result?${
        category ? `slug="jobs"&genre=${category}` : "genre=jobs"
      }${type ? `&type=${type}` : `&type=offering`} ${
        keyword ? `&key=${keyword}` : ""
      }`
    );
  };

  useEffect(() => {
    const data = searchData.find((val) => val.label === "type");
    setType(data?.value);
  }, [searchData]);

  useEffect(() => {
    localStorage.removeItem("search-data");
    scrollToTop();
    dispatch(fetchGetAds("jobs", latitude, longitude, location, token));
    dispatch(fetchGetJobsCategories());
    dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeAdsData());
      dispatch(removeJobsData());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchGetAds("jobs", latitude, longitude, location, token));
    const filter = [
      {
        label: "latitudes[]",
        value: latitude,
      },
      {
        label: "longitudes[]",
        value: longitude,
      },
      {
        label: "location",
        value: location,
      },
    ];
    latitude !== "" &&
      longitude !== "" &&
      localStorage.setItem("search-data", JSON.stringify(filter));
    return () => {
      dispatch(removeAdsData());
    };
  }, [latitude, longitude, location]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    setTimeout(() => {
      message &&
        dispatch(fetchGetAds("jobs", latitude, longitude, location, token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      errMsg &&
        dispatch(fetchGetAds("jobs", latitude, longitude, location, token));
    }, 100);
  }, [errMsg]);

  if (loading || jobsLoading || catLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories ? (
        <div
          className="absolute w-full h-full bg-black opacity-10"
          onClick={() => {
            setShowCategories(false);
          }}
        ></div>
      ) : null}
      <Categories showCategories={showCategories} />
      <Head color="#0388B7" setShowCategories={setShowCategories} />
      <HeroSection
        pic={jobsHome}
        filterData={filterData}
        searchData={searchData}
        setSearchData={setSearchData}
        categories={categories}
        setCategory={setCategory}
        setKeyword={setKeyword}
        keyword={keyword}
        color="#0388B7"
        handleSearch={handleSearch}
        slug="jobs"
      />
      {token ? null : (
        <Signup
          color="#0388B7"
          bgColor="rgb(3 136 183 / var(--tw-bg-opacity))"
        />
      )}
      <div className="w-11/12 xl:w-3/4 mx-auto bg-white py-10">
        {categories && categories.length ? (
          <>
            <Title
              more={false}
              color="#0388B7"
              title1="Our Categories"
              title2="Explore By Category"
              para="Explore the latest ads showcasing a plethora of products and services, providing a seamless online marketplace experience for buyers and sellers alike."
            />
            <div className="mt-4 w-full grid grid-cols-3 md:grid-cols-4 gap-x-5">
              {categories.map((val) => {
                return (
                  <GridCategories
                    handleClick={() =>
                      navigate(
                        `/search-result?slug=jobs&genre=${val.slug}&type=offering`
                      )
                    }
                    image={val.cat_image}
                    name={val.cat_name}
                    key={val.cat_id}
                    color="#0388B7"
                  />
                );
              })}
            </div>
          </>
        ) : null}

        {token ? null : <ResgisterAds color="#0388B7" />}

        {ads && ads.length ? (
          <div className="mt-20">
            <Title
              more={true}
              color="#0388B7"
              title1="our Products"
              title2="Explore Our Latest Ads"
              handleClick={() =>
                navigate(`/search-result?genre=jobs&type=offering`)
              }
            />

            {ads.length > 3 ? (
              <AdsCard
                data={ads}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
                slugs="jobs"
                category="jobs"
              />
            ) : (
              <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                {ads.map((val) => {
                  return (
                    <div key={val.post_id}>
                      <GridAdsCard
                        data={val}
                        slugs="jobs"
                        category="jobs"
                        handleAddToWishlist={handleAddToWishlist}
                        handleRemoveWishlist={handleRemoveWishlist}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <Newsletter
        color="#0388B7"
        bgColor="rgb(3 136 183 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default Jobs;
