import React from "react";
import { IoMdAddCircleOutline, IoMdArrowDropdown } from "react-icons/io";
import Button from "../Button/Button";
import { MdArrowDropDown, MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../NavigateTop";

const Head = ({
  color,
  setShowCategories,
  showLocation,
  setShowLocation,
  location,
  setKeyword,
  handleClickSearch,
  keyword,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
      <div className="w-full grid grid-cols-12 gap-x-5 place-items-center">
        <div
          className="col-span-6 sm:col-span-3 lg:col-span-2 border rounded-xl py-3 w-full flex items-center gap-x-3 justify-center cursor-pointer"
          onClick={() => setShowCategories(true)}
        >
          <p>Categories</p>
          <IoMdArrowDropdown />
        </div>
        <div className="col-start-3 col-end-11 hidden lg:block w-full">
          {window.location.pathname === "/" ||
          window.location.pathname === "/cars" ||
          window.location.pathname === "/jobs" ||
          window.location.pathname === "/bikes" ||
          window.location.pathname === "/real-estate" ? (
            <p className="text-sm text-center">
              Summer Sale For All Swim Suits And Free Express Delivery - OFF
              50%!{" "}
              <span className="font-semibold" style={{ color: color }}>
                SHOP NOW
              </span>
            </p>
          ) : (
            <div className="w-full mx-auto border bg-white rounded-xl flex items-center justify-between">
              <div
                className="w-52 border-r h-full py-1 px-3 flex items-center justify-end cursor-pointer"
                onClick={() => {
                  scrollToTop();
                  setShowLocation(!showLocation);
                }}
              >
                <div className="flex items-center gap-x-3 w-full">
                  <MdLocationOn className="text-2xl text-primary" />
                  <p className="text-sm truncate text-gray-700 w-3/4">
                    {location ? location : "Location"}{" "}
                  </p>
                </div>
                <MdArrowDropDown className="text-3xl text-gray-500" />
              </div>

              <div className="w-full px-3 flex items-center gap-x-3">
                <input
                  type="text"
                  className="w-full py-1 outline-none border-none bg-white text-sm text-secondary"
                  placeholder="What are you looking for"
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </div>
              <Button
                value="Search"
                handleClick={handleClickSearch}
                className={`w-fit text-white py-3 px-7 text-sm rounded-r-xl`}
                style={{ backgroundColor: color }}
              />
            </div>
          )}
        </div>
        <div
          style={{ background: color }}
          onClick={() => navigate("/me/post-ads")}
          className={`col-start-7 sm:col-start-10 lg:col-start-11 col-end-13 border flex items-center gap-x-3 py-3 cursor-pointer justify-center w-full rounded-xl text-white`}
        >
          <IoMdAddCircleOutline className="text-2xl" />
          <p>Post an Ad</p>
        </div>
      </div>

      {window.location.pathname === "/" ||
      window.location.pathname === "/cars" ||
      window.location.pathname === "/jobs" ||
      window.location.pathname === "/bikes" ||
      window.location.pathname === "/real-estate" ? null : (
        <div className="w-full mx-auto border bg-white rounded-xl flex lg:hidden mt-10 items-center justify-between">
          <div
            className="w-52 border-r h-full py-1 px-3 flex items-center justify-end cursor-pointer"
            onClick={() => {
              scrollToTop();
              setShowLocation(!showLocation);
            }}
          >
            <div className="flex items-center gap-x-3 w-full">
              <MdLocationOn className="text-2xl text-primary" />
              <p className="text-sm truncate text-gray-700 w-3/4">
                {location ? location : "Location"}{" "}
              </p>
            </div>
            <MdArrowDropDown className="text-3xl text-gray-500" />
          </div>

          <div className="w-full px-3 flex items-center gap-x-3">
            <input
              type="text"
              className="w-full py-1 outline-none border-none bg-white text-sm text-secondary"
              placeholder="What are you looking for"
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword ? keyword : ""}
            />
          </div>
          <Button
            value="Search"
            style={{ background: color }}
            handleClick={handleClickSearch}
            className="w-fit text-white py-3 px-7 text-sm rounded-r-xl"
          />
        </div>
      )}
    </div>
  );
};

export default Head;
