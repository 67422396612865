import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { Auth } from "./Auth/reducer";
import { Categories } from "./Category/reducer";
import { Ads } from "./Ads/reducer";
import { Cars } from "./Cars/reducer";
import { Jobs } from "./Jobs/reducer";
import { Wishlist } from "./Wishlist/reducer";
import { Notification } from "./Notification/reducer";
import { Profile } from "./Profile/reducer";
import { Dashboard } from "./Dashboard/reducer";
import { MyAds } from "./MyAds/reducer";
import { Payment } from "./Payments/reducer";
import { Privacy } from "./PrivacySetting/reducer";
import { TermsPrivacy } from "./Terms-Privacy/reducer";
import { Aboutus } from "./Aboutus/reducer";
import { Message } from "./Message/reducer";
import { Blogs } from "./Blogs/reducer";
import { Request } from "./Request/reducer";
import { Contact } from "./Contact/reducer";
import { PostImage } from "./PostImage/reducer";
import { News } from "./News/reducer";
import { Newsletter } from "./Newsletter/reducer";
import { Faqs } from "./Faq/reducer";
import { AgentRequest } from "./AgentRequest/reducer";
import { Location } from "./Location/reducer";
import { Referral } from "./Referral/reducer";
import { Logo } from "./Logo/reducer";
import { savedSearch } from "./SavedSearch/reducer";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      auth: Auth,
      categories: Categories,
      ads: Ads,
      cars: Cars,
      jobs: Jobs,
      wishlist: Wishlist,
      notification: Notification,
      profile: Profile,
      dashboard: Dashboard,
      myAds: MyAds,
      payment: Payment,
      privacy: Privacy,
      termsprivacy: TermsPrivacy,
      aboutus: Aboutus,
      message: Message,
      blogs: Blogs,
      request: Request,
      contact: Contact,
      postImages: PostImage,
      news: News,
      newsletter: Newsletter,
      faq: Faqs,
      agentRequest: AgentRequest,
      location: Location,
      referral: Referral,
      logo: Logo,
      savedSearch: savedSearch,
    }),
    process.env.NODE_ENV === "development"
      ? compose(composeWithDevTools(applyMiddleware(...[thunk, logger])))
      : compose(applyMiddleware(...[thunk]))
  );

  return store;
};
