import React, { useEffect, useState } from "react";
import {
  AdsCard,
  Categories,
  Category,
  Download,
  FeaturedCategory,
  GridAdsCard,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
  ResgisterAds,
  Signup,
  Title,
} from "../../Component";
import Hero from "../../Component/Banner/Hero";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetPopularCategories } from "../../Redux/Category/action";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  fetchGetAds,
  fetchGetSpotlight,
  removeAdsData,
} from "../../Redux/Ads/action";
import { useNavigate } from "react-router-dom";
import {
  fetchAddtoWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Home = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [showCategories, setShowCategories] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.ads.isloading, shallowEqual);
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const categories = useSelector(
    (state) => state.categories.popular,
    shallowEqual
  );

  const spotted = useSelector((state) => state.ads.spotted, shallowEqual);

  const ads = useSelector((state) => state.ads.ads, shallowEqual);

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddtoWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetPopularCategories());
    dispatch(fetchGetAds("all", latitude, longitude, location, token));
    dispatch(fetchGetSpotlight(token));
    localStorage.removeItem("search-data");
    return () => {
      dispatch(removeAdsData());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchGetAds("all", latitude, longitude, location, token));
    dispatch(fetchGetSpotlight(token));

    const filter = [
      {
        label: "latitudes[]",
        value: latitude,
      },
      {
        label: "longitudes[]",
        value: longitude,
      },
      {
        label: "location",
        value: location,
      },
    ];
    latitude !== "" &&
      longitude !== "" &&
      localStorage.setItem("search-data", JSON.stringify(filter));
    return () => {
      dispatch(removeAdsData());
    };
  }, [latitude, longitude]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    setTimeout(() => {
      message &&
        dispatch(fetchGetAds("all", latitude, longitude, location, token));
      message && dispatch(fetchGetSpotlight(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      errMsg &&
        dispatch(fetchGetAds("all", latitude, longitude, location, token));
      errMsg && dispatch(fetchGetSpotlight(token));
    }, 100);
  }, [errMsg]);

  if (catLoading || loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 h-full relative">
      {showLocation || showCategories ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowLocation(false);
            setShowCategories(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setLocation={setLocation}
          location={location}
        />
      )}
      <div className="w-full h-full">
        <Categories
          showCategories={showCategories}
          setShowCategories={setShowCategories}
        />
      </div>

      <Head color="#2E8B4A" setShowCategories={setShowCategories} />

      <hr className="w-full mt-5" />
      <Hero
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />
      {token ? null : (
        <Signup
          color="#2E8B4A"
          bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
        />
      )}
      <div className="w-11/12 xl:w-3/4 mx-auto bg-white py-10 relative">
        <Title
          more={false}
          color="#2E8B4A"
          title1="Our Categories"
          title2="Explore By Category"
        />

        <Category data={categories} />
        {token ? null : <ResgisterAds color="#2E8B4A" />}

        {spotted && spotted.length ? (
          <div className="mt-20">
            <Title
              more={false}
              color="#2E8B4A"
              title1="Our Products"
              title2="Explore Our Spotted Ads"
            />

            {spotted.length > 3 ? (
              <AdsCard
                data={spotted}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            ) : (
              <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                {spotted.map((val) => {
                  return (
                    <div key={val.post_id}>
                      <GridAdsCard
                        data={val}
                        handleAddToWishlist={handleAddToWishlist}
                        handleRemoveWishlist={handleRemoveWishlist}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}

        {ads && ads.length ? (
          <div className="mt-20">
            <Title
              more={true}
              color="#2E8B4A"
              title1="Our Products"
              title2="Explore Our Latest Ads"
              handleClick={() =>
                navigate(`/search-result?genre=all&type=offering&key=`)
              }
            />

            {ads.length > 3 ? (
              <AdsCard
                data={ads}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            ) : (
              <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                {ads.map((val) => {
                  return (
                    <div key={val.post_id}>
                      <GridAdsCard
                        data={val}
                        handleAddToWishlist={handleAddToWishlist}
                        handleRemoveWishlist={handleRemoveWishlist}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}
        <Download />

        <div className="mt-20">
          <Title
            more={false}
            color="#2E8B4A"
            title1="Features"
            title2="Explore Our Featured Categories"
          />
          <FeaturedCategory />
        </div>
      </div>

      {/* <EnquiryAd /> */}
      <Newsletter
        color="#2E8B4A"
        bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default Home;
