import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const locationLoading = () => ({
  type: ActionTypes.LOCATION_LOADING,
});

export const removeLocationData = () => ({
  type: ActionTypes.REMOVE_LOCATION_DATA,
});

export const fetchGetLocationByState = (key) => async (dispatch) => {
  await axios.get(`${BaseUrl}/address_new?address=${key}`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_LOCATION_BY_STATE,
      payload: data,
    });
  });
};
