import React, { useEffect, useState } from "react";
import {
  AdsCard,
  Advertisement,
  Breadcrum,
  Button,
  Categories,
  GridAdsCard,
  Head,
  Loading,
  LocationPopup,
  Map,
  Share,
  Title,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAddInspection,
  fetchDeleteInspection,
  fetchGetAdsDetail,
  fetchReportAds,
  removeAdsData,
} from "../../Redux/Ads/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { classiBazarLogo } from "../../Assets/Assest";
import {
  BsBagCheck,
  BsBagCheckFill,
  BsBookmarkCheck,
  BsFillArrowUpRightCircleFill,
  BsFillEyeFill,
} from "react-icons/bs";
import { AiFillHeart, AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import moment from "moment/moment";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchAddtoWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import RespondAds from "./RespondAds";
import { fetchGetAllCategories } from "../../Redux/Category/action";

const radio = [
  {
    id: 1,
    name: "Duplicate",
  },

  {
    id: 2,
    name: "Scam",
  },

  {
    id: 3,
    name: "Mis Categorized",
  },

  {
    id: 4,
    name: "No Longer Available",
  },

  {
    id: 5,
    name: "Unresponsive Poster",
  },

  {
    id: 6,
    name: "Other",
  },
];

const AdsDetail = () => {
  const [showShare, setShowShare] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [postId, setPostId] = useState("");
  const [respondAds, setRespondAds] = useState({
    is_contact: false,
    is_offer: false,
    is_apply: false,
  });

  const [issue, setIssue] = useState("");
  const [reason, setReason] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const location = useLocation();
  const search = useLocation().search;

  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [locations, setLocations] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setPostId(new URLSearchParams(search).get("i"));
  }, [location.search]);

  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const loading = useSelector((state) => state.ads.isloading, shallowEqual);
  const detail = useSelector((state) => state.ads.detail, shallowEqual);

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const inspMsg = useSelector((state) => state.ads.message, shallowEqual);
  const inspErrMsg = useSelector((state) => state.ads.errMsg, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const [images, setImages] = useState(detail?.images);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "5px",
  };

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: locations,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  const handleClickSeeMore = (id) => {
    navigate(`/search-result?genre=all&type=offering&posted_by=${id}`);
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddtoWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  const handleAddInspection = (inspectionId, postId) => {
    scrollToTop();
    const body = {
      post_id: postId,
      inspection_id: inspectionId,
    };

    dispatch(fetchAddInspection(body, token));
  };

  const handleRemoveInspection = (id) => {
    scrollToTop();
    const body = {
      inspection_id: id,
    };

    dispatch(fetchDeleteInspection(body, token));
  };

  const handleReportAd = () => {
    scrollToTop();
    if (!token) {
      toast.error("Authentication fail. Please signin!");
    } else if (!issue) {
      toast.error("Please select reason");
    } else if (!reason && issue === "Other") {
      toast.error("Please provide the reason");
    } else {
      const body = {
        post_id: postId,
        reason: issue,
        txt_reason: issue === "Other" ? reason : issue,
      };

      setInProgress(true);

      dispatch(fetchReportAds(body, token));
    }
  };

  useEffect(() => {
    scrollToTop();
    postId && dispatch(fetchGetAdsDetail(postId, token));
    localStorage.removeItem("search-data");
    postId && dispatch(fetchGetAllCategories());

    return () => {
      dispatch(removeAdsData());
    };
  }, [postId]);

  useEffect(() => {
    if (detail) {
      setImages(detail?.images);
    }
  }, [detail]);

  useEffect(() => {
    if (images) {
      setSelectedImage(images?.[0]);
    }
  }, [images]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeAdsData());
    message && dispatch(removeWishlistData());
    message &&
      setRespondAds({
        is_contact: false,
        is_offer: false,
        is_apply: false,
      });
    setTimeout(() => {
      message && dispatch(fetchGetAdsDetail(postId, token));
    }, 100);
  }, [message]);

  useEffect(() => {
    inspMsg && dispatch(removeAdsData());
    inspMsg && toast.success(inspMsg);
    inspMsg &&
      setRespondAds({
        is_contact: false,
        is_offer: false,
        is_apply: false,
      });

    inspMsg && setIssue("");
    inspMsg && setReason("");
    inspMsg && setInProgress(false);
    inspMsg && dispatch(fetchGetAdsDetail(postId, token));
  }, [inspMsg]);

  useEffect(() => {
    inspErrMsg && dispatch(removeAdsData());
    inspErrMsg && toast.success(inspErrMsg);
    inspErrMsg && setInProgress(false);
    inspErrMsg && dispatch(fetchGetAdsDetail(postId, token));
  }, [inspErrMsg]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    errMsg && dispatch(removeAdsData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetAdsDetail(postId, token));
    }, 100);
  }, [errMsg]);

  if (loading || !postId || catLoading || inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {profile?.userid === detail?.post_owner?.userid ? null : (
        <div className="w-full h-16 bg-headerColor fixed z-40 bottom-0 md:hidden  flex gap-x-5 items-center justify-between px-3 py-4">
          <div
            className="flex items-center gap-x-2 justify-center cursor-pointer bg-primary p-2 w-full rounded-md"
            onClick={() => {
              scrollToTop();
              setRespondAds({
                is_contact: true,
                is_offer: false,
                is_apply: false,
              });
            }}
          >
            <HiOutlineChatAlt2 className="text-white text-2xl" />
            <p className="text-base text-white">Contact Us</p>
          </div>

          {detail?.categories?.[0] === "Jobs" ? (
            <div
              className="flex items-center gap-x-2 cursor-pointer bg-primary p-2 w-full rounded-md justify-center"
              onClick={() => {
                scrollToTop();
                if (token) {
                  setRespondAds({
                    is_contact: false,
                    is_offer: false,
                    is_apply: true,
                  });
                } else {
                  toast.error("Authentication failed. Please login");
                }
              }}
            >
              <BsBagCheckFill className="text-white text-2xl" />

              <p className="text-base text-white">Apply Now</p>
            </div>
          ) : (
            <div
              className="flex items-center gap-x-2 cursor-pointer bg-primary p-2 w-full rounded-md justify-center"
              onClick={() => {
                scrollToTop();
                setRespondAds({
                  is_contact: false,
                  is_offer: true,
                  is_apply: false,
                });
              }}
            >
              <BsBookmarkCheck className="text-white text-2xl" />
              <p className="text-base text-white">Make an Offer</p>
            </div>
          )}
        </div>
      )}

      {showCategories ||
      respondAds.is_contact ||
      respondAds.is_offer ||
      respondAds.is_apply ||
      showShare ||
      showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowCategories(false);
            setShowShare(false);
            setShowLocation(false);
            setRespondAds({
              is_contact: false,
              is_offer: false,
              is_apply: false,
            });
          }}
        ></div>
      ) : null}

      {respondAds.is_contact || respondAds.is_offer || respondAds.is_apply ? (
        <RespondAds
          setRespondAds={setRespondAds}
          respondAds={respondAds}
          postId={postId}
          setInProgress={setInProgress}
        />
      ) : null}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setLocation={setLocations}
        />
      )}

      <Categories showCategories={showCategories} />

      <Head
        setShowCategories={setShowCategories}
        color="#2E8B4A"
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={locations}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
        keyword={keyword}
      />
      <hr className="w-full mt-7" />

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10 relative">
        {showShare && (
          <Share URL={`${window.location.href}`} setShowShare={setShowShare} />
        )}
        <Breadcrum
          title={detail?.categories?.[0]}
          subTitle={detail?.categories?.[1]}
          subTitle2={detail?.categories?.[2]}
        />

        <div className="w-full grid grid-cols-12 mt-10 gap-x-5">
          <div className="w-full col-span-12 md:col-span-5">
            <div className="w-full h-52 lg:h-64 relative rounded-xl overflow-hidden">
              {selectedImage?.image_name ? (
                <img
                  src={selectedImage?.image_name}
                  alt="img"
                  className="w-full h-full object-fill"
                />
              ) : (
                <img
                  src={classiBazarLogo}
                  alt=""
                  className="w-full h-full object-fill"
                />
              )}
            </div>

            {/* mini image section */}
            <div className="mt-10  w-full overflow-hidden hover:overflow-x-auto relative">
              <div className="flex items-center gap-x-4 w-fit">
                {images &&
                  images.map((data) => {
                    return (
                      <div
                        className={`w-32 h-32 border p-2 rounded-2xl cursor-pointer ${
                          selectedImage?.image_id === data?.image_id
                            ? "border-green-600"
                            : ""
                        }`}
                        key={data?.image_id}
                        onClick={() => setSelectedImage(data)}
                      >
                        <img
                          src={data?.image_name}
                          alt="mini images"
                          className="w-full h-full rounded-2xl"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="w-full col-start-1 md:col-start-6 col-end-13 px-3 mt-10 md:mt-0">
            {/* title */}
            <div className="w-full flex items-center justify-between gap-x-5">
              <h6 className="font-semibold text-xl md:text-2xl w-2/3 md:w-3/4 truncate">
                {detail?.title}
              </h6>
              <div className="flex items-center gap-x-3 w-fit">
                <BsFillEyeFill className="text-primary text-sm lg:text-xl" />
                <p className="text-xs lg:text-sm">{detail?.view_count} views</p>
              </div>
            </div>

            {/* price and share */}
            <div className="w-full flex items-center justify-between mt-3">
              <div className="flex items-center gap-x-3">
                {detail.show_price === "No" ? null : (
                  <h5 className="font-semibold text-xl md:text-2xl">
                    {" "}
                    {detail?.price !== "0"
                      ? detail?.price_type !== "2"
                        ? `$${detail?.price} 
                    `
                        : `$${detail?.price} - $${detail?.to_price}  
                    `
                      : ""}
                  </h5>
                )}
              </div>

              <div className="flex items-center gap-x-5">
                {detail.is_wishlist ? (
                  <div
                    className="flex items-center gap-x-2 cursor-pointer"
                    onClick={() => handleRemoveWishlist(postId)}
                  >
                    <AiFillHeart className="text-red-500 text-2xl" />
                    <p>Saved</p>
                  </div>
                ) : (
                  <div
                    className="flex items-center gap-x-2 cursor-pointer"
                    onClick={() => handleAddToWishlist(postId)}
                  >
                    <AiOutlineHeart className="text-2xl" />
                    <p>Save</p>
                  </div>
                )}

                <div
                  className="flex items-center gap-x-2 cursor-pointer"
                  onClick={() => setShowShare(true)}
                >
                  <AiOutlineShareAlt className="text-2xl" />
                  <p>Share</p>
                </div>
              </div>
            </div>

            {/* owner */}
            <div className="w-full mt-10 flex gap-x-3">
              <div className="w-16 h-14 rounded-full overflow-hidden">
                {detail?.post_owner?.image ? (
                  <img
                    src={detail?.post_owner?.image}
                    alt=""
                    className="w-full h-full object-fill"
                  />
                ) : (
                  <img
                    src={classiBazarLogo}
                    alt=""
                    className="w-full h-full object-fill"
                  />
                )}
              </div>
              <div className="flex flex-col w-full">
                <div className="w-full flex justify-between items-center flex-wrap md:flex-nowrap">
                  <h6 className="font-semibold text-xl w-full md:w-3/4">
                    {detail?.post_owner?.first_name}{" "}
                    {detail?.post_owner?.last_name}
                  </h6>
                  <div
                    className="flex w-40 lg:items-center gap-x-3 font-medium cursor-pointer hover:underline text-base text-primary"
                    onClick={() => handleClickSeeMore(detail.posted_by)}
                  >
                    <p className="text-xs lg:text-base">See All Ads</p>
                    <BsFillArrowUpRightCircleFill />
                  </div>
                </div>
                <p className="text-sm text-gray-400 mt-1">
                  Member since{" "}
                  {moment(detail?.post_owner?.join_date).format("LL")}
                </p>
              </div>
            </div>

            <hr className="w-full mt-6" />
            <div className="w-full flex items-center gap-x-10 lg:justify-between flex-wrap ">
              <div
                className="flex items-center gap-x-2 cursor-pointer mt-5"
                onClick={() => setShowPhone(!showPhone)}
              >
                <BiPhoneCall className="text-primary text-2xl" />
                {showPhone ? (
                  <p>{detail?.post_owner?.user_mobile}</p>
                ) : (
                  <p>{detail?.post_owner?.user_mobile.slice(0, 5)}****</p>
                )}
              </div>

              {profile?.userid === detail?.post_owner?.userid ? null : (
                <>
                  <div
                    className="hidden md:flex items-center gap-x-2 cursor-pointer mt-5"
                    onClick={() => {
                      scrollToTop();
                      setRespondAds({
                        is_contact: true,
                        is_offer: false,
                        is_apply: false,
                      });
                    }}
                  >
                    <HiOutlineChatAlt2 className="text-primary text-2xl" />
                    <p>Contact Us</p>
                  </div>

                  {detail?.categories?.[0] === "Jobs" ? (
                    <div
                      className="hidden md:flex items-center gap-x-2 cursor-pointer mt-5"
                      onClick={() => {
                        scrollToTop();
                        if (token) {
                          setRespondAds({
                            is_contact: false,
                            is_offer: false,
                            is_apply: true,
                          });
                        } else {
                          toast.error("Authentication failed. Please login");
                        }
                      }}
                    >
                      <BsBagCheck className="text-primary text-2xl" />

                      <p className="text-base">Apply Now</p>
                    </div>
                  ) : (
                    <div
                      className="hidden md:flex items-center gap-x-2 cursor-pointer mt-5"
                      onClick={() => {
                        scrollToTop();
                        setRespondAds({
                          is_contact: false,
                          is_offer: true,
                          is_apply: false,
                        });
                      }}
                    >
                      <BsBookmarkCheck className="text-primary text-3xl" />
                      <p className="text-base ">Make an Offer</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <hr className="w-full mt-6" />
          </div>
        </div>

        <div className="w-full grid grid-cols-12 mt-16 lg:gap-x-10 h-full">
          <div className="col-span-12 lg:col-span-7 w-full h-fit">
            {/* description */}
            <div className="w-full overflow-hidden">
              <p className="font-medium">Description</p>
              <p className="text-sm mt-5 w-full text-gray-600">
                {detail?.description}
              </p>
            </div>
            <hr className="w-full mt-5" />
            {/* features */}

            <div className="mt-5 w-full">
              <p className="font-medium">Details</p>
              <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-5">
                {detail?.post_features &&
                  detail?.post_features.map((val, i) => {
                    return val?.title !== "Name" &&
                      val?.title !== "Ad Status" ? (
                      detail.show_price === "No" &&
                      val?.title === "To Ad Price" ? null : (
                        <div
                          className="mt-5 w-full flex items-center flex-col p-4 border rounded-xl bg-gray-100"
                          key={i}
                        >
                          <p className="font-medium text-center">
                            {val?.title}
                          </p>
                          {val?.value && Array.isArray(val?.value) ? (
                            val?.value?.map((itm, i) => {
                              return (
                                <ul
                                  key={i}
                                  className="text-sm text-gray-600 mt-1"
                                >
                                  <li style={{ listStyleType: "circle" }}>
                                    {itm.title}
                                  </li>
                                </ul>
                              );
                            })
                          ) : (
                            <p className="text-sm text-gray-600 mt-3 text-center">
                              {val?.value}
                            </p>
                          )}
                        </div>
                      )
                    ) : null;
                  })}
              </div>
            </div>

            <hr className="w-full mt-5" />

            {/* Inspections */}
            {detail.inspection_times && detail.inspection_times.length ? (
              <>
                <div className="mt-5">
                  <h4 className="font-medium mb-4">Inspections</h4>

                  <div
                    className={`w-full border overflow-y-auto rounded-lg mt-5 ${
                      detail.inspection_times?.length > 3 ? "h-80" : "h-fit"
                    }`}
                  >
                    {detail.inspection_times &&
                      detail.inspection_times.map((val, i) => {
                        return (
                          <div
                            className="flex flex-wrap p-5 items-center justify-between border-b pb-5"
                            key={i}
                          >
                            <div>
                              <h6 className="text-lg font-medium text-textPrimary">
                                {new Date(val.inspection_date).toDateString()}
                              </h6>

                              <p className="text-textSecondary mt-2">
                                {moment(val.start_time, "HH:mm:ss")
                                  .local()
                                  .format("LT")}
                                -
                                {moment(val.end_time, "HH:mm:ss")
                                  .local()
                                  .format("LT")}
                              </p>
                            </div>
                            {profile?.userid ===
                            detail?.post_owner?.userid ? null : (
                              <div className="flex items-center gap-x-5 mt-5 lg:mt-0 ">
                                {val.inspection_added ? (
                                  <Button
                                    value="In Plan"
                                    handleClick={() =>
                                      handleRemoveInspection(val.id)
                                    }
                                    className="w-fit px-5 py-3 border transition duration-1000 ease-in-out hover:bg-green-50 text-green-700 border-green-700 rounded-lg cursor-pointer"
                                  />
                                ) : (
                                  <Button
                                    value="+ Add to Plan"
                                    handleClick={() =>
                                      handleAddInspection(val.id, val.post_id)
                                    }
                                    className="w-fit px-5 py-3 border transition duration-1000 ease-in-out hover:bg-gray-100 text-textPrimary border-gray-200 rounded-lg cursor-pointer"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <hr className="w-full mt-5" />
              </>
            ) : null}

            {/* map */}
            <div className="w-full h-full mt-5">
              <p className="font-medium mb-4">Location</p>
              <Map
                latitude={detail?.latitude}
                longitude={detail?.longitude}
                className={mapContainerStyle}
              />
            </div>
          </div>
          <div className="col-start-1 lg:col-start-8 col-end-13 w-full h-fit mt-10 lg:mt-0">
            {profile?.userid === detail?.post_owner?.userid ? null : (
              <div className="w-full rounded-xl border">
                <p className="border-b bg-gray-200 p-3 rounded-t-xl">
                  Report This Ad
                </p>
                {radio.map((val) => {
                  return (
                    <div
                      className="flex items-center gap-x-3 w-full mt-1 p-3"
                      key={val.id}
                    >
                      <input
                        type="radio"
                        className="w-4 h-4"
                        name="radio"
                        value={issue}
                        checked={issue === val.name}
                        onChange={() => setIssue(val.name)}
                      />
                      <label>{val.name}</label>
                    </div>
                  );
                })}

                {issue === "Other" ? (
                  <div className="p-3 mt-3 w-full">
                    <textarea
                      type="textarea"
                      className="border border-gray-300 w-full p-3 h-40 text-sm rounded-lg outline-gray-300"
                      placeholder="Provide Information"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                ) : null}

                <hr className="w-full mt-3" />
                <div className="mt-5 w-full flex items-center gap-x-5 p-3">
                  <Button
                    value="Cancel"
                    handleClick={() => {
                      setIssue("");
                      setReason("");
                    }}
                    className="bg-gray-200 rounded-xl w-full py-3 px-7"
                  />
                  <Button
                    value="Send Report"
                    handleClick={handleReportAd}
                    className="bg-primary text-white rounded-xl w-full py-3 px-7"
                  />
                </div>
              </div>
            )}

            <Advertisement className="w-full h-96 mt-10" />
          </div>
        </div>

        <hr className="w-full mt-10" />
        <div className="w-full my-10">
          <Title
            more={false}
            color="#2E8B4A"
            title1="Similar Products"
            title2="You may also like"
          />

          {detail?.similar_posts && detail?.similar_posts ? (
            <>
              {detail.similar_posts.length > 3 ? (
                <AdsCard
                  data={detail?.similar_posts}
                  handleAddToWishlist={handleAddToWishlist}
                  handleRemoveWishlist={handleRemoveWishlist}
                />
              ) : (
                <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                  {detail?.similar_posts.map((val) => {
                    return (
                      <div key={val.post_id}>
                        <GridAdsCard
                          data={val}
                          handleAddToWishlist={handleAddToWishlist}
                          handleRemoveWishlist={handleRemoveWishlist}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdsDetail;
