import React from "react";
import Button from "../Button/Button";

const HeroSection = ({
  pic,
  filterData,
  searchData,
  setSearchData,
  categories,
  setCategory,
  setKeyword,
  keyword,
  color,
  handleSearch,
  slug,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    const data = searchData.filter((elm) => elm.label === name);

    if (data.length) {
      const data = searchData.filter((elm) => elm.label !== name);
      setSearchData([
        ...data,
        {
          label: name,
          value: value,
        },
      ]);
    } else {
      setSearchData([
        ...searchData,
        {
          label: name,
          value: value,
        },
      ]);
    }
  };
  return (
    <div className="border w-full overflow-hidden h-[43rem] md:h-[37rem] mt-8 relative">
      <div className="w-full h-full absolute bg-gray-700 bg-opacity-70"></div>
      <div className="w-full h-full">
        <img src={pic} alt="js" className="w-full h-full object-cover" />
      </div>

      <div className="absolute top-14 h-[65%] md:h-[55%] left-[4%] md:left-[15%] 2xl:left-[25%] w-11/12 md:w-3/4 2xl:w-1/2">
        <h2 className="text-white font-semibold text-3xl leading-10 text-center">
          {slug === "jobs"
            ? "Classi Jobs"
            : slug === "bikes"
            ? "Classi Bikes"
            : slug === "cars"
            ? "Classi Cars"
            : slug === "realestate"
            ? "Classi Estate"
            : ""}
        </h2>
        <p className="text-white text-center mt-3">Awesome Verified Ads!</p>

        <div className="w-full h-full bg-white px-5 mt-10 rounded-xl overflow-y-auto relative">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 h-fit">
            {categories && categories.length ? (
              <div className="text-sm w-full h-fit mt-10">
                <label className="pb-2 font-medium flex  text-sm items-center gap-x-1">
                  Categories
                </label>

                <select
                  className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" selected disabled>
                    Select categories
                  </option>
                  {categories &&
                    categories?.map((itm) => {
                      return (
                        <option key={itm.cat_id} value={itm.slug}>
                          {itm.cat_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            ) : null}

            {filterData &&
              filterData.map((val, i) => {
                return val?.childs && val.childs.length ? (
                  <div className="text-sm h-fit w-full mt-10" key={i}>
                    <label className="pb-2 font-medium flex  text-sm items-center gap-x-1">
                      {val?.attr?.label}
                    </label>

                    <select
                      name={val?.attr?.name}
                      onChange={handleChange}
                      value={searchData[val?.attr?.name]}
                      className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                    >
                      <option value="" selected disabled>
                        Select {val?.attr?.label}
                      </option>
                      {val?.childs?.map((itm) => {
                        return (
                          <option key={itm.value} value={itm.value}>
                            {itm.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null;
              })}

            <div className="text-sm mt-10">
              <div className="w-full flex items-center gap-x-5 pb-2">
                <label className="font-medium flex text-sm items-center gap-x-1 w-full">
                  Keyword
                </label>
              </div>

              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                placeholder="Keyword"
              />
            </div>
          </div>
          <div className="flex w-full justify-end mt-5 pb-5 bottom-0 bg-white sticky">
            <Button
              value="search"
              handleClick={handleSearch}
              className="w-fit rounded-xl py-3 text-white px-7"
              style={{ backgroundColor: color }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
