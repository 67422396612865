import React, { useEffect } from "react";
import { Button, FormikControl } from "../../Component";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  fetchGetUserProfile,
  fetchVerifyMobile,
  removeProfilesData,
} from "../../Redux/Profile/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const VerifyNumber = ({ setShowOtpPopup }) => {
  const message = useSelector((state) => state.profile.verifyMsg, shallowEqual);
  const errMessage = useSelector(
    (state) => state.profile.verifyErr,
    shallowEqual
  );

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const initialValues = {
    mobile: "",
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long")
      .required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      mobile_number: values.mobile,
    };

    dispatch(fetchVerifyMobile(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setShowOtpPopup(true);
    message && dispatch(removeProfilesData());
    setTimeout(() => {
      message && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeProfilesData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [errMessage]);

  return (
    <div className="w-full mt-10 bg-white border rounded-xl relative">
      <p className="p-5 text-lg font-medium">Verify Your Mobile Number</p>
      <hr className="w-full" />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formik) => (
          <Form>
            <div className="w-full flex-wrap lg:w-3/4 xl:w-2/3 border flex items-center gap-x-5 p-5">
              <div className="w-3/4 mt-7">
                <FormikControl
                  label="Phone Number"
                  setShowOtpPopup={setShowOtpPopup}
                  control="input"
                  name="mobile"
                  type="number"
                  placeholder="Enter Your Phone Number"
                />
              </div>
              <Button
                value="Verify Mobile"
                className="w-fit mt-5 md:mt-14 py-3 px-5 rounded-xl text-sm bg-primary text-white cursor-pointer"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyNumber;
