import React from "react";
import { Link } from "react-router-dom";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";
import { FaFacebookF } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { QR, appStore, playStore } from "../../Assets/Assest";

const classiBazzar = [
  { id: 1, name: "About Classibazaar", link: "/about-us" },
  { id: 2, name: "Contact Us", link: "/contact-us" },
  // { id: 3, name: "Quick Enquire", link: "#" },
  {
    id: 4,
    name: "Display Ads",
    link: "/search-result?genre=all&type=offering&key=",
  },
  { id: 5, name: "Register", link: "/auth/sign-up" },
];

const explore = [
  // { id: 1, name: "Top Searches", link: "#" },
  {
    id: 2,
    name: "All Locations",
    link: "/search-result?genre=all&type=offering&key=",
  },
  { id: 3, name: "Blog", link: "/blogs" },
  { id: 4, name: "API Request", link: "/api-request" },
  { id: 5, name: "ClassiSupplier Request", link: "/classisupplier-request" },
];

const categories = [
  // { id: 1, name: "Pets", link: "/search-result?genre=pets&type=offering" },
  { id: 2, name: "Jobs", link: "/jobs" },
  { id: 3, name: "Estate", link: "/real-estate" },
  { id: 4, name: "Bike", link: "/bikes" },
  { id: 5, name: "ClassiCars", link: "/cars" },
  {
    id: 6,
    name: "All Categories",
    link: "/search-result?genre=all&type=offering&key=",
  },
];

const legal = [
  { id: 1, name: "Terms of Use", link: "/terms-of-use" },
  { id: 2, name: "Privacy Policy", link: "/privacy-policy" },
  { id: 3, name: "Listing Policy", link: "/listing-policy" },
  { id: 4, name: "Cookie Policy", link: "/cookie-policy" },
];

const Footer = () => {
  return (
    <div className="w-full bg-footerColor py-16">
      <div className="w-11/12  mx-auto">
        <div className="w-full grid grid-cols-12 gap-x-5">
          <div className="col-span-12 md:col-span-4 mt-7">
            <h3 className="text-base font-semibold text-white">Follow Us</h3>
            <p className="text-white text-sm mt-2">
              Connect with us on Social Media
            </p>

            <div className="w-full flex items-center flex-wrap gap-x-2 xl:gap-x-5 mt-5">
              <a
                href="https://www.facebook.com/classibazaar"
                target="_blank"
                className="rounded-full p-3 bg-headerColor cursor-pointer"
              >
                <FaFacebookF className="text-white text-xl" />
              </a>
              <a
                href="https://www.instagram.com/classibazaar"
                target="_blank"
                className="rounded-full p-3 bg-headerColor cursor-pointer"
              >
                <AiFillInstagram className="text-white text-2xl" />
              </a>
              <a
                href="https://twitter.com/classibazaar"
                target="_blank"
                className="rounded-full p-3 bg-headerColor cursor-pointer"
              >
                <AiOutlineTwitter className="text-white text-2xl" />
              </a>
              <a
                href="https://www.linkedin.com/company/classibazaar/"
                target="_blank"
                className="rounded-full p-3 bg-headerColor cursor-pointer"
              >
                <BiLogoLinkedin className="text-white text-2xl" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCm1gM4xyWUa-_OL7QiF14Ng"
                target="_blank"
                className="rounded-full p-3 bg-headerColor cursor-pointer"
              >
                <BsYoutube className="text-white text-2xl" />
              </a>
            </div>

            <div className="mt-10">
              <h6 className="text-white font-semibold">Download App</h6>

              <div className="flex w-full items-center gap-x-5 mt-5 flex-wrap lg:flex-nowrap">
                <div className="w-44 h-32 overflow-hidden mt-5">
                  <img src={QR} className="w-full h-full object-contain" />
                </div>
                <div className="w-full flex flex-col mt-5">
                  <a href="https://play.google.com/store/apps/details?id=com.classibazaarapp">
                    <div className="w-44 h-12 overflow-hidden">
                      <img
                        src={playStore}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </a>

                  <a href="https://apps.apple.com/au/app/classibazaar/id1496587117?ls=1">
                    <div className="w-44 h-12 overflow-hidden mt-2">
                      <img
                        src={appStore}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-start-1 md:col-start-5 col-end-13 px-5 flex gap-x-10 xl:justify-between flex-wrap">
            <div className="w-fit mt-7">
              <h3 className="text-lg font-semibold text-white mb-5">
                Classibazaar
              </h3>
              {classiBazzar.map((val) => {
                return (
                  <Link
                    to={val.link}
                    key={val.id}
                    className="text-white flex flex-col py-2 text-sm"
                  >
                    {val.name}
                  </Link>
                );
              })}
            </div>

            <div className="w-fit mt-7">
              <h3 className="text-lg font-semibold text-white mb-5">Explore</h3>
              {explore.map((val) => {
                return (
                  <Link
                    key={val.id}
                    to={val.link}
                    onClick={() => {
                      if (val.name === "All Locations") {
                        localStorage.removeItem("search-data");
                        localStorage.removeItem("search-location");
                      }
                    }}
                    className="text-white flex flex-col py-2 text-sm"
                  >
                    {val.name}
                  </Link>
                );
              })}
            </div>

            <div className="w-fit mt-7">
              <h3 className="text-lg font-semibold text-white mb-5">
                Top Categories
              </h3>
              {categories.map((val) => {
                return (
                  <Link
                    key={val.id}
                    to={val.link}
                    className="text-white flex flex-col py-2 text-sm"
                  >
                    {val.name}
                  </Link>
                );
              })}
            </div>

            <div className="w-fit mt-7">
              <h3 className="text-lg font-semibold text-white mb-5">Legal</h3>
              {legal.map((val) => {
                return (
                  <Link
                    key={val.id}
                    to={val.link}
                    className="text-white flex flex-col py-2 text-sm"
                  >
                    {val.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
