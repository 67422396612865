import React from "react";
import { classiBazarLogo } from "../../Assets/Assest";

const BlogsCard = ({ data }) => {
  const removeUnwantedContent = (content) => {
    return content.replace(/(\r\n\t|&nbsp;|\s\s+)/g, "");
  };

  return (
    <div className="mt-5 w-full border rounded-xl h-52 overflow-hidden cursor-pointer">
      <div className="flex gap-x-5 w-full h-full ">
        <div className="w-1/3 h-52 overflow-hidden rounded-lg">
          {data.picture ? (
            <img
              src={data.picture}
              alt=""
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt=""
              className="w-full h-full object-contain"
            />
          )}
        </div>
        <div className="w-2/3 pt-3">
          <p className="text-sm font-medium">{data.category}</p>
          <hr className="w-full mt-2" />

          <div className="mt-3 w-full">
            <p className="truncates font-medium">{data.title}</p>
            <p className="text-sm truncate2 text-start mt-2 leading-6 w-11/12">
              {removeUnwantedContent(data.content)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsCard;
