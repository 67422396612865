import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const adsLoading = () => ({
  type: ActionTypes.ADS_LOADING,
});

export const removeAdsData = () => ({
  type: ActionTypes.REVOME_ADS,
});

export const fetchGetSpotlight = (token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/spoted_ads`,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_SPOTLIGHT,
        payload: data,
      });
    });
};

export const fetchGetAds =
  (slug, latitude, longitude, location, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/ads_list?slug=${slug}${
          latitude
            ? `&latitudes[]=${latitude}&longitude[]=${longitude}&location=${location}`
            : ""
        }`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_ADS,
          payload: data,
        });
      });
  };

export const fetchGetFilteredAds =
  (slug, keyword, type, page, postby, filterData, token) =>
  async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/ads_list?slug=${slug}&keyword=${keyword}&type=${type}${
          page && page !== undefined ? `&page=${page}` : ""
        }${postby && postby !== undefined ? `&posted_by=${postby}` : ""}${
          filterData && filterData.length
            ? filterData
                .map((val) => {
                  return `&${val.label}=${val.value}`;
                })
                .join("")
            : ""
        }`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_FILTERED_ADS,
          payload: data,
        });
      });
  };

export const fetchGetAdsDetail = (id, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/ads_detail/${id}`,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADS_DETAIL,
        payload: data,
      });
    });
};

export const fetchAddInspection = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/add_inspections`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_INSPECTION_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_INSPECTION_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchDeleteInspection = (id, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/delete_inspections`, id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_INSPECTION_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_INSPECTION_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchRespondAds = (body, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/respond_ads`,
      body,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.RESPOND_AD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.RESPOND_AD_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchReportAds = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/report_ad`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.REPORT_ADS_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.REPORT_ADS_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchChangeAdStatus = (status, id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/change_ad_status?status=${status}&postid=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_AD_STATUS_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CHANGE_AD_STATUS_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchMakeAdCompleted = (status, id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/make_ad_completed?postid=${id}&status=${status}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.MAKE_AD_COMPLETED_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.MAKE_AD_COMPLETED_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchSaveSearch = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/saved_searches`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SAVE_SEARCH_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SAVE_SEARCH_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchDeleteSaveSearch = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/delete_searches`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_SAVE_SEARCH_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_SAVE_SEARCH_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchGetSaveSearch = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/me/my_searches`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_SAVE_SEARCH,
        payload: data,
      });
    });
};
