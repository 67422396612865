import React from "react";
import { referral1, referral3, referralLeftArrow } from "../../Assets/Assest";
import { shallowEqual, useSelector } from "react-redux";
import { FaShareAlt } from "react-icons/fa";
import { Share } from "../../Component";

const InviteFriends = ({ setShowShare, showShare }) => {
  const qrCode = useSelector((state) => state.referral.qrCode, shallowEqual);
  const inviteLink = useSelector(
    (state) => state.referral.inviteLink,
    shallowEqual
  );

  return (
    <div className="mt-10 w-full">
      {/* how it works */}
      <div className="w-full border rounded-xl bg-white">
        <p className="p-5 font-medium">How It Works </p>
        <hr className="w-full" />
        <div className="px-5 mt-5 w-full flex items-center gap-x-5">
          <img src={referral1} alt="" className="w-10 h-10 object-contain" />
          <p className="font-medium text-sm md:text-base">
            Invite your friend to install the app with the link
          </p>
        </div>

        <div className="w-full px-5 mt-2">
          <img
            src={referralLeftArrow}
            alt=""
            className="w-10 h-10 object-contain"
          />
        </div>
        <div className="px-5 mt-2 pb-5 w-full flex items-center gap-x-5">
          <img src={referral3} alt="" className="w-10 h-10 object-contain" />
          <p className="font-medium text-sm md:text-base">
            Get 20 Classi Coin and your friend gets 10 Classi Coins
          </p>
        </div>
      </div>

      {/* referral links */}
      <div className="w-full border rounded-xl bg-white mt-10 relative">
        {showShare && <Share setShowShare={setShowShare} URL={inviteLink} />}

        <p className="p-5 font-medium">Your Invite Link </p>
        <hr className="w-full" />

        <div className="mt-5 p-5">
          <div className="w-full flex items-center justify-center">
            <img src={qrCode} alt="" className="w-40 h-40" />
          </div>

          <div className="w-full border-dashed p-5 border-2 border-primary rounded-xl mt-7">
            <div className="text-sm">
              <div className="w-full flex items-center gap-x-5 pb-2">
                <label className="font-medium flex text-sm items-center justify-between w-full">
                  Your Unique Link
                </label>
              </div>
              <div className="w-full flex items-center gap-x-5">
                <input
                  className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                  value={inviteLink}
                  readOnly
                />
                <div
                  className="w-fit p-3.5 bg-primary rounded-lg cursor-pointer"
                  onClick={() => setShowShare(!showShare)}
                >
                  <FaShareAlt className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
