import React, { useState } from "react";
import { MdMoney, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { classiBazarLogo } from "../../Assets/Assest";
import { AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../NavigateTop";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ChangeStatus from "../../Pages/MyAds/ChangeStatus";

const MyAdsCard = ({
  data,
  setPost,
  setShowDeletePopup,
  setShowStatusPopup,
  showStatusPopup,
  toggle,
}) => {
  const [selectedPostId, setSelectedPostId] = useState("");
  const [status, setStatus] = useState("");

  const navigate = useNavigate();
  return (
    <div className="w-full border rounded-lg bg-white mt-7 relative">
      {showStatusPopup && selectedPostId === data.post_id ? (
        <ChangeStatus
          status={status}
          setStatus={setStatus}
          setShowStatusPopup={setShowStatusPopup}
          setSelectedPostId={setSelectedPostId}
          selectedPostId={selectedPostId}
          toggle={toggle}
        />
      ) : null}
      <div className="w-full flex justify-between p-3 items-center">
        <div className="w-fit flex items-center gap-x-3">
          <p
            className={`w-fit py-1 px-5 border rounded-full text-sm bg-gray-100 text-gray-600`}
          >
            {data.status}
          </p>
          <div className="w-full lg:flex items-center gap-x-3 hidden">
            {data.featured === "1" && (
              <p
                className={`w-fit py-1 px-5 rounded-full text-sm bg-green-100 text-primary border border-primary`}
              >
                Featured
              </p>
            )}

            {data.urgent === "1" && (
              <p
                className={`w-fit py-1 px-5 rounded-full text-sm bg-red-100 text-carPrimary border border-carPrimary`}
              >
                Urgent
              </p>
            )}

            {data.spoted === "1" && (
              <p
                className={`w-fit py-1 px-5 rounded-full text-sm bg-blue-100 text-jobPrimary border border-jobPrimary`}
              >
                Spotlight
              </p>
            )}

            {data.logo === "1" && (
              <p
                className={`w-fit py-1 px-5 rounded-full text-sm bg-gray-500 text-white`}
              >
                Logo
              </p>
            )}
          </div>
        </div>

        <div
          className="w-fit flex items-center gap-x-1 text-sm text-gray-600 hover:underline cursor-pointer"
          onClick={() =>
            navigate(`/ads-detail?i=${data.post_id}&name=${data.title}`)
          }
        >
          <p>Ad Details</p>
          <MdOutlineKeyboardArrowRight />
        </div>
      </div>

      <div className="w-full lg:hidden items-center gap-x-3 flex flex-wrap px-3 pb-5">
        {data.featured === "1" && (
          <p
            className={`w-fit py-1 px-5 mt-3 rounded-full text-sm bg-green-100 text-primary border border-primary`}
          >
            Featured
          </p>
        )}

        {data.urgent === "1" && (
          <p
            className={`w-fit py-1 px-5 mt-3 rounded-full text-sm bg-red-100 text-carPrimary border border-carPrimary`}
          >
            Urgent
          </p>
        )}

        {data.spoted === "1" && (
          <p
            className={`w-fit py-1 px-5 mt-3 rounded-full text-sm bg-blue-100 text-jobPrimary border border-jobPrimary`}
          >
            Spotlight
          </p>
        )}

        {data.logo === "1" && (
          <p
            className={`w-fit py-1 px-5 mt-3 rounded-full text-sm bg-gray-100 text-gray-600 `}
          >
            Logo
          </p>
        )}
      </div>
      <hr className="w-full" />
      <div className="w-full p-3 mt-5">
        <div className="w-full grid grid-cols-12 gap-x-5">
          <div className="col-span-12 lg:col-span-4 2xl:col-span-3 w-full h-40 overflow-hidden">
            {data.thumbnail ? (
              <img
                src={data.thumbnail}
                alt=""
                className="w-full h-full object-cover"
              />
            ) : (
              <img
                src={classiBazarLogo}
                alt=""
                className="w-ful h-full object-cover"
              />
            )}
          </div>
          <div className="col-start-1 lg:col-start-5 2xl:col-start-4 col-end-13 mt-5 lg:mt-0">
            <p className="w-full text-sm font-medium truncates">{data.title}</p>

            <div className="w-full flex flex-wrap 2xl:flex-nowrap items-center justify-between mt-6">
              <div className="w-full 2xl:w-3/4">
                <p className="text-sm">
                  {" "}
                  <span className="text-gray-400">category:</span>{" "}
                  {data.catname}
                </p>
                <p className="text-sm mt-2 truncate">
                  {" "}
                  <span className="text-gray-400">Location:</span>{" "}
                  {data.ad_address}
                </p>

                <p className="text-sm mt-2">
                  {" "}
                  <span className="text-gray-400">Type:</span> {data.offer_type}
                </p>
              </div>

              <div className="flex 2xl:flex-none items-center gap-x-2 mt-2 2xl:mt-0">
                <p className="text-sm text-gray-400 text-end">Total:</p>
                <p className="text-lg font-semibold">
                  {" "}
                  {data.to_price > data.price
                    ? ` $${data.price}-$${data.to_price}`
                    : `$${data.price}`}
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="w-full mt-7" />

        <div className="w-full flex flex-wrap lg:flex-nowrap items-center justify-between">
          <p className="text-sm text-gray-500 2xl:block hidden mt-4">
            Added {data.posted_date}
          </p>

          <div className="w-fit flex items-center flex-wrap gap-x-5">
            {data.status !== "Completed" ? (
              <div
                className="w-fit border rounded-md py-2 px-3 cursor-pointer flex items-center gap-x-2 mt-4"
                onClick={() => {
                  setShowStatusPopup(true);
                  setSelectedPostId(data.post_id);
                  setStatus(data.status);
                }}
              >
                <IoMdInformationCircleOutline className="text-primary text-xl" />
                <p className="text-sm">Change Status</p>
              </div>
            ) : null}

            {data.status === "Active" && data.offer_type !== "wanted" ? (
              <div
                className="w-fit border rounded-md py-2 px-3 cursor-pointer flex items-center gap-x-2 mt-4"
                onClick={() => navigate(`/me/my-ads?upgrade=${data.post_id}`)}
              >
                <MdMoney className="text-primary text-xl" />
                <p className="text-sm">Upgrade Post</p>
              </div>
            ) : null}

            {data.status !== "Completed" ? (
              <div
                className="w-fit border rounded-md py-2 px-3 cursor-pointer flex items-center gap-x-2 mt-4"
                onClick={() => navigate(`/edit-form/${data.post_id}`)}
              >
                <AiOutlineEdit className="text-jobPrimary text-xl" />
                <p className="text-sm">Edit Post</p>
              </div>
            ) : null}

            {data.status !== "Completed" ? (
              <div
                className="w-fit border rounded-md py-2 px-3 cursor-pointer flex items-center gap-x-2 mt-4"
                onClick={() => {
                  scrollToTop();
                  setPost({
                    id: data.post_id,
                    name: data.title,
                  });
                  setShowDeletePopup(true);
                }}
              >
                <AiOutlineCloseCircle className="text-carPrimary text-xl" />
                <p className="text-sm">Delete Post</p>
              </div>
            ) : null}
          </div>
        </div>

        <p className="text-sm text-gray-500 2xl:hidden block mt-4">
          Added {data.posted_date}
        </p>
      </div>
    </div>
  );
};

export default MyAdsCard;
