import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const profileLoading = () => ({
  type: ActionTypes.PROFILE_LOADING,
});

export const removeProfilesData = () => ({
  type: ActionTypes.REMOVE_PROFILE_DATA,
});

export const fetchGetUserProfile = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/profile_new`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PROFILE,
        payload: data,
      });
    });
};

export const fetchUploadImage = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/upload_image`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPLOAD_IMAGE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPLOAD_IMAGE_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchUpdateProfile = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/profile`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchUpdatePassword = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/user_update_password`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_PASSWORD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_PASSWORD_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchVerifyMobile = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/verify_mobile`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_MOBILE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_MOBILE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchVerifyCode = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/add_code`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_CODE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_CODE_FAIL,
        payload: e.response.data,
      });
    });
};
