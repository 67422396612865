import React from "react";
import { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Navigate, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loading from "../../Component/Loading/Loading";
import { AiOutlineSend } from "react-icons/ai";
import { toast } from "react-toastify";
import { BsCheck, BsCheck2All } from "react-icons/bs";
import ScrollToBottom from "react-scroll-to-bottom";
import { RiAttachment2 } from "react-icons/ri";
import {
  fetchGetChatList,
  fetchGetOfferListInPost,
  fetchSendFile,
  fetchSendMessage,
  removeInboxData,
} from "../../Redux/Message/action";

const MyInbox = () => {
  const [userName, setUserName] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  let params = new URLSearchParams(document.location.search);
  let postId = params.get("indx");

  const loading = useSelector((state) => state.message.isloading, shallowEqual);
  const replies = useSelector((state) => state.message.replies, shallowEqual);
  const errMessage = useSelector((state) => state.message.errMsg, shallowEqual);

  const chatList = useSelector((state) => state.message.chatList, shallowEqual);
  const file = useSelector((state) => state.message.file, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleClickChat = (postid, offerid, userName) => {
    localStorage.setItem("offerId", offerid);
    dispatch(fetchGetChatList(postid, offerid, token));
    setUserName(userName);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const offerId = localStorage.getItem("offerId");

      const body = {
        comment: e.target.value,
        post_id: postId,
        file_name: file.src,
        offer_id: offerId,
        app_name: "CLASSIESTATE",
      };

      dispatch(fetchSendMessage(body, token));
      dispatch(removeInboxData());
      setTimeout(() => {
        dispatch(fetchGetOfferListInPost(postId, token));
        dispatch(fetchGetChatList(postId, offerId, token));
        setMessage("");
      }, 100);
    }
  };

  useEffect(() => {
    file && setMessage(image?.name);
  }, [file]);

  const handleClickSend = () => {
    const offerId = localStorage.getItem("offerId");

    const body = {
      comment: message,
      post_id: postId,
      file_name: file.src,
      offer_id: offerId,
      app_name: "CLASSIESTATE",
    };

    dispatch(fetchSendMessage(body, token));
    dispatch(removeInboxData());
    setTimeout(() => {
      dispatch(fetchGetOfferListInPost(postId, token));
      dispatch(fetchGetChatList(postId, offerId, token));
      setMessage("");
    }, 100);
  };

  useEffect(() => {
    postId && dispatch(fetchGetOfferListInPost(postId, token));
    return () => {
      dispatch(removeInboxData());
    };
  }, [postId]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const sortedDateTimes = [...chatList].sort(
      (a, b) => new Date(a.added_at) - new Date(b.added_at)
    );
    setMessages(sortedDateTimes);
  }, [chatList]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeInboxData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetOfferListInPost(postId, token));
    }, 1000);
  }, [errMessage]);

  if (!token) {
    return <Navigate to={`/auth/sign-in`} replace />;
  }

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 h-full w-full bg-gray-50">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-5 2xl:mt-10">
        <div
          className="text-sm md:text-base flex items-center gap-x-2 text-textPrimary cursor-pointer w-fit"
          onClick={handleNavigateBack}
        >
          <BsArrowLeft />
          <p>Go Back</p>
        </div>

        <div className="w-full grid grid-cols-12 h-[35rem] mt-5 2xl:mt-10 bg-white">
          <div className="w-full col-span-4 h-full border overflow-y-auto rounded-l-xl pb-2">
            <h5 className="font-medium text-xl p-5">Messages</h5>
            <hr className="w-full" />

            {replies && replies.length
              ? replies.map((val) => {
                  return (
                    <div
                      key={val.id}
                      className="px-3 hover:bg-gray-50 cursor-pointer w-full overflow-hidden"
                      onClick={() =>
                        handleClickChat(val.post_id, val.id, val.offer_name)
                      }
                    >
                      <div className="flex w-full gap-x-5 py-5">
                        <div className="w-10 h-8 lg:w-14 lg:h-12">
                          <img
                            src={val.image}
                            alt=""
                            className="w-full h-full rounded-full object-fill"
                          />
                        </div>
                        <div className="w-11/12 overflow-hidden">
                          <div className="w-full flex items-center justify-between">
                            <h6 className="font-medium text-base truncate w-3/4">
                              {val.offer_name}
                            </h6>
                            {val.unseen_msg ? (
                              <div className="w-7 h-7 flex justify-center items-center text-center border rounded-full bg-primary text-white text-xs">
                                <p>{val.unseen_msg}</p>
                              </div>
                            ) : null}
                          </div>
                          <p className="text-textSecondary truncate text-sm mt-1">
                            {val?.latest?.file_name
                              ? "sent file"
                              : val?.latest?.comment}
                          </p>
                        </div>
                      </div>
                      <hr className="w-full text-textSecondary" />
                    </div>
                  );
                })
              : null}
          </div>
          <div
            className={`${
              messages && messages.length ? "block" : "hidden"
            } w-full col-start-5 col-end-13 bg-pink-50 h-full border overflow-y-auto rounded-r-xl`}
          >
            <h5 className="font-medium text-xl p-5 bg-white">{userName}</h5>
            <hr className="w-full" />

            <div className="w-full h-[70%] overflow-y-auto">
              <ScrollToBottom className="h-full w-full overflow-y-scroll px-5">
                {messages && messages.length
                  ? messages.map((val) => {
                      const fileExtension = val.file_name
                        ? val.file_name.split(".").pop()
                        : "";

                      const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                        fileExtension.toLowerCase()
                      );
                      const isDocument = [
                        "pdf",
                        "doc",
                        "docx",
                        "xls",
                        "xlsx",
                      ].includes(fileExtension.toLowerCase());

                      return (
                        <div
                          key={val.id}
                          className={`${
                            profile.userid === val.user_id
                              ? "flex-row-reverse"
                              : ""
                          } flex w-full p-2 gap-x-5`}
                        >
                          <div className="w-[2.6rem] mt-2 h-10">
                            <img
                              src={
                                val.commented_user_photo
                                  ? val.commented_user_photo
                                  : val.offered_user_photo
                              }
                              alt=""
                              className="w-full h-full rounded-full object-fill"
                            />
                          </div>
                          <div
                            className={` ${
                              profile.userid === val.user_id
                                ? "text-left text-textSecondary bg-white w-fit rounded-r-xl rounded-bl-xl"
                                : "text-left text-textSecondary bg-white w-fit rounded-l-xl rounded-br-xl"
                            } py-2 px-4 mt-2`}
                          >
                            {isDocument ? (
                              <a
                                className={`text-sm ${
                                  val.file_name ? "text-blue-500" : ""
                                }`}
                                href={val.file_name}
                                download={val.file_name}
                              >
                                {val.comment}
                              </a>
                            ) : isImage ? (
                              <>
                                <img
                                  src={val.file_name}
                                  alt="val.comment"
                                  className="w-40 h-32 object-contain"
                                />
                                <p className={`text-sm mt-2`}>{val.comment}</p>
                              </>
                            ) : (
                              <>
                                <p className={`text-sm `}>{val.comment}</p>
                                {val.request_type === "0" ? (
                                  <p className="text-sm mt-2">
                                    Offer Price: {val.offer_price}
                                  </p>
                                ) : null}
                              </>
                            )}
                            <div className="flex items-center mt-2 gap-x-2">
                              <p className="text-[0.6rem]  text-textSecondary">
                                {val.added_date}
                              </p>
                              {profile.userid === val.user_id ? (
                                val.is_seen === "1" ? (
                                  <BsCheck2All className="text-xs text-primary" />
                                ) : (
                                  <BsCheck className="text-sm text-textSecondary" />
                                )
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </ScrollToBottom>
            </div>
            <div className="w-full h-[17.5%] gap-x-5 bg-white flex justify-between items-center px-5">
              <div
                className={`border rounded-full w-11/12 outline-none text-sm text-textSecondary flex items-center ${
                  message === image?.name ? "pl-5" : "px-5"
                }`}
              >
                <input
                  type="text"
                  placeholder="Write message in here..."
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  disabled={message === image?.name ? true : false}
                  className="outline-none py-2 md:py-3 w-full"
                />
                {message === image?.name ? (
                  <span
                    className="bg-red-700 w-14 cursor-pointer py-2 text-center md:py-3 text-white rounded-r-full"
                    onClick={() => {
                      setMessage("");
                      setImage("");
                      dispatch(removeInboxData());
                      setTimeout(() => {
                        dispatch(fetchGetOfferListInPost(postId, token));
                      }, 100);
                    }}
                  >
                    X
                  </span>
                ) : null}
              </div>
              <label className="w-fit p-3 bg-primary text-white rounded-full cursor-pointer">
                <RiAttachment2 className="text-white text-xl" />
                <input
                  type="file"
                  className="w-full h-full border hidden"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    const formData = new FormData();
                    formData.append("files", e.target.files[0]);
                    dispatch(fetchSendFile(formData, token));
                  }}
                />
              </label>
              <div
                className="p-3 cursor-pointer bg-primary text-white rounded-full"
                onClick={handleClickSend}
              >
                <AiOutlineSend className="text-xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInbox;
