import Cookies from "js-cookie";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { Breadcrum, Loading, OTPpopup } from "../../Component";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAllCategories,
  removeCategoryData,
} from "../../Redux/Category/action";
import { useState } from "react";
import Category from "./Category";
import CategoryDetail from "./CategoryDetail";
import Form from "./Form";
import VerifyNumber from "./VerifyNumber";

const PostAds = () => {
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [toggle, setToggle] = useState({
    category: true,
    detail: false,
    form: false,
  });

  const [firstCat, setFirstCat] = useState({
    id: "",
    name: "",
  });
  const [secCat, setSecCat] = useState({
    id: "",
    name: "",
  });
  const [thriCat, setThriCat] = useState({
    id: "",
    name: "",
  });
  const [frthCat, setFrthCat] = useState({
    id: "",
    name: "",
  });

  const token = Cookies.get("token");
  const dispatch = useDispatch();

  const { id } = useParams();

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const profileLoading = useSelector(
    (state) => state.profile.isloading,
    shallowEqual
  );

  useEffect(() => {
    id &&
      setToggle({
        category: false,
        detail: false,
        form: true,
      });
  }, [id]);

  useEffect(() => {
    toggle.category && dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeCategoryData());
    };
  }, [toggle.category]);

  if (!token) {
    return <Navigate to={`/auth/sign-in`} replace />;
  }

  if (loading || profileLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showOtpPopup ? (
        <div
          className="w-full h-full bg-black opacity-10 absolute z-30"
          onClick={() => {
            setShowOtpPopup(false);
          }}
        ></div>
      ) : null}
      <div className="absolute w-3/4 left-[15%]">
        {showOtpPopup && <OTPpopup setOtpPopup={setShowOtpPopup} />}
      </div>
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10 relative">
        <Breadcrum title="Home" subTitle="Post an Ad" />
        {profile.mobile_verified === 0 || profile.mobile_verified === "0" ? (
          <VerifyNumber setShowOtpPopup={setShowOtpPopup} />
        ) : (
          <div className="w-full mt-10">
            {toggle.category && (
              <Category
                firstCat={firstCat}
                setFirstCat={setFirstCat}
                setToggle={setToggle}
                setSecCat={setSecCat}
                setThriCat={setThriCat}
                setFrthCat={setFrthCat}
              />
            )}

            {toggle.detail && (
              <CategoryDetail
                setToggle={setToggle}
                secCat={secCat}
                setSecCat={setSecCat}
                firstCat={firstCat}
                thriCat={thriCat}
                setThriCat={setThriCat}
                frthCat={frthCat}
                setFrthCat={setFrthCat}
              />
            )}

            {toggle.form && (
              <Form
                firstCat={firstCat}
                secCat={secCat}
                thriCat={thriCat}
                frthCat={frthCat}
                setToggle={setToggle}
                setSecCat={setSecCat}
                setThriCat={setThriCat}
                setFrthCat={setFrthCat}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostAds;
