import React, { useEffect, useState } from "react";
import { Categories, Head, Loading, LocationPopup } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Campaign = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [showCategories, setShowCategories] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const categories = useSelector(
    (state) => state.categories.popular,
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  useEffect(() => {
    const filter = [
      {
        label: "latitudes[]",
        value: latitude,
      },
      {
        label: "longitudes[]",
        value: longitude,
      },
      {
        label: "location",
        value: location,
      },
    ];
    localStorage.setItem("search-data", JSON.stringify(filter));
  }, [latitude, longitude, location]);

  if (catLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showLocation || showCategories ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowLocation(false);
            setShowCategories(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocation}
            location={location}
          />
        </div>
      )}

      <Categories showCategories={showCategories} />

      <Head
        setShowCategories={setShowCategories}
        color="#2E8B4A"
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />
      <hr className="w-full mt-5" />
      <div className="11/12 xl:w-3/4 mx-auto mt-10">
        <h5 className="text-center text-3xl font-semibold">Sorry!!</h5>
        <p className="text-center text-xl font-medium mt-3">
          No Campaign Found
        </p>
      </div>
    </div>
  );
};

export default Campaign;
