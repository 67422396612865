import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Breadcrum = ({ title, subTitle, subTitle2, link }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full items-center gap-x-3 flex text-sm">
      <p
        className="text-gray-400 cursor-pointer hover:underline"
        onClick={() => navigate("/")}
      >
        {title}
      </p>
      <MdKeyboardArrowRight className="text-gray-400" />
      <p
        className="text-gray-400 cursor-pointer hover:underline"
        onClick={link}
      >
        {subTitle}
      </p>
      {subTitle2 ? (
        <>
          <MdKeyboardArrowRight className="text-gray-400" />
          <p className="font-medium">{subTitle2}</p>
        </>
      ) : null}
    </div>
  );
};

export default Breadcrum;
