import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, FormikControl, Loading } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchLogin,
  fetchSocialLogin,
  removeAuthData,
} from "../../Redux/Auth/action";
import { toast } from "react-toastify";
import { googleIcon, logo } from "../../Assets/Assest";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.auth.message, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  //   initial values
  const initialValues = {
    email: "",
    password: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required*"),
    password: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    dispatch(fetchLogin(values));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeAuthData());
    message && navigate("/");
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeAuthData());
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        })
        .then(({ data }) => {
          const body = {
            email: data.email,
            first_name: data.given_name,
            last_name: data.family_name,
            token: data.id,
          };
          scrollToTop();
          dispatch(fetchSocialLogin(body));
        });
    },
  });

  return (
    <div className="py-12 md:py-28 bg-slate-200 w-full h-full">
      <div className="w-11/12 xl:w-3/4 mx-auto h-full rounded-xl mt-14 bg-white grid grid-cols-12 lg:divide-x-2 pb-10 lg:pb-0">
        <div className="col-span-12 lg:col-span-6 xl:col-span-7 w-full h-full py-5 px-7">
          <img src={logo} width="200" height="200" alt="logo" />
          <h6 className="font-semibold text-xl md:text-2xl mt-7">
            Welcome to Classibazaar!
          </h6>
          <p className="text-secondary text-sm py-2">Log into your account</p>
          <div className="mt-8">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className="w-full">
                    <FormikControl
                      label="Email Address"
                      control="input"
                      name="email"
                      placeholder="Your email address"
                    />
                  </div>
                  <div className="w-full mt-5">
                    <FormikControl
                      label="Password"
                      control="password"
                      name="password"
                      placeholder="Your Password"
                      type={`${showPassword ? "text" : "password"}`}
                      setShowPassword={setShowPassword}
                      showPassword={showPassword}
                    />
                  </div>
                  <p
                    className="text-secondary font-medium w-full cursor-pointer text-sm md:text-base hover:underline mt-4 "
                    onClick={() => navigate("/auth/forgot-password")}
                  >
                    Forgot Your Password?
                  </p>
                  <p className="text-secondary text-sm mt-8">
                    By proceeding, you agree to our
                  </p>
                  <div className="flex items-center gap-x-3 text-sm mt-5">
                    <Link to="/terms-of-use">
                      <p className="text-secondary hover:underline cursor-pointer font-medium">
                        Terms of Service
                      </p>
                    </Link>
                    <div className="border-r-2 h-4 text-textSecondary"></div>
                    <Link to="/privacy-policy">
                      <p className="text-secondary hover:underline cursor-pointer font-medium">
                        Privacy Policies
                      </p>
                    </Link>
                  </div>

                  <div className="mt-7 w-full flex items-center flex-wrap md:flex-nowrap justify-between">
                    <Button
                      value="Sign in"
                      className="w-full rounded-xl bg-primary text-white py-2 md:py-3"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="col-start-1 lg:col-start-7 xl:col-start-8 col-end-13 w-11/12 mx-auto h-full py-5 px-3 md:px-8 mt-7 md:mt-10 lg:mt-0 border-t lg:border-t-0">
          <div className="flex items-center flex-wrap md:flex-nowrap justify-center w-full md:w-2/3 lg:w-10/12 mx-auto">
            <p className="text-sm text-secondary w-full text-center md:text-start">
              Don&apos;t have account?
            </p>
            <Button
              value="Register"
              handleClick={() => navigate("/auth/sign-up")}
              className="border rounded-full mt-5 md:mt-0 text-primary py-2 w-fit px-10 hover:bg-primary hover:text-white transition-all ease-in-out duration-300"
            />
          </div>
          <div className="w-full md:w-2/3 mx-auto lg:w-full h-full mt-14 md:mt-20 lg:mt-32">
            <div className=" flex justify-center gap-x-4 md:gap-x-7 xl:gap-x-5 2xl:gap-x-7 items-center w-full">
              <hr className="w-full xl:w-1/2 2xl:w-full text-textSecondary border" />
              <p className="w-full text-xs md:text-sm">Or Sign in with</p>
              <hr className="w-full xl:w-1/2 wxl:w-full text-textSecondary border" />
            </div>
            <div
              className="w-full rounded-full py-3 px-5 md:pr-16 cursor-pointer mt-10 border flex justify-between items-center transition duration-500 ease-in-out hover:border-primary hover:text-primary"
              onClick={() => googleLogin()}
            >
              <img
                src={googleIcon}
                alt="google"
                width={25}
                height={25}
                className="object-fill"
              />
              <p className="text-center">Continue with Google</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
