import React, { useEffect, useMemo, useState } from "react";
import {
  Categories,
  Head,
  Loading,
  LocationPopup,
  Pagination,
  SinglePagnination,
  TopAdvertisement,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import Filter from "./Filter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import {
  fetchGetFilteredAds,
  fetchSaveSearch,
  removeAdsData,
} from "../../Redux/Ads/action";
import { MdKeyboardArrowRight, MdNotificationsActive } from "react-icons/md";
import moment from "moment";
import { BsFillGridFill } from "react-icons/bs";
import { HiViewList } from "react-icons/hi";
import GridSearch from "./GridSearch";
import ListingSearch from "./ListingSearch";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  fetchAddtoWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import { LuSettings2 } from "react-icons/lu";
import { IoMdNotificationsOutline } from "react-icons/io";

const SearchLList = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [slug, setSlug] = useState();

  const [categ, setCateg] = useState();
  const data = localStorage.getItem("search-data");
  const parsedData = JSON.parse(data);

  const [filterData, setFilterData] = useState(parsedData ? parsedData : []);
  const [location, setLocation] = useState(
    parsedData
      ? parsedData?.find((elem) => elem.label === "location")?.value
      : ""
  );

  const [showLocation, setShowLocation] = useState(false);

  const data2 = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data2);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [locations, setLocations] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );

  let params = new URLSearchParams(document.location.search);
  let category = params.get("genre");
  let type1 = params.get("type");
  let key = params.get("key");
  let slugs = params.get("slug");
  let pg = params.get("page");
  let postBy = params.get("posted_by");

  const [page, setPage] = useState(pg ? Number(pg) : 1);
  const [keyword, setKeyword] = useState(key ? key : "");
  const [type, setType] = useState(type1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const adsLoading = useSelector((state) => state.ads.isloading, shallowEqual);
  const totalData = useSelector((state) => state.ads.totalData, shallowEqual);
  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);
  const saveMsg = useSelector((state) => state.ads.message, shallowEqual);
  const saveErrMsg = useSelector((state) => state.ads.errMsg, shallowEqual);
  const searchedData = useSelector(
    (state) => state.ads.searchData,
    shallowEqual
  );

  const metaData = useSelector((state) => state.ads.meta);
  const totalPage = Math.ceil(totalData / 8);

  const [price, setPrice] = useState({
    minPrice: metaData.min_price,
    maxPrice: metaData.max_price,
  });

  useEffect(() => {
    const price = parsedData
      ? parsedData?.find((elem) => elem.label === "price")?.value
      : "";

    console.log("priceeeeeeeeeee", price);
    // const parts = [];

    const parts = price?.split("__");

    console.log("priceeeeeeeeeee splittt", parts);

    // Extract minPrice and maxPrice
    const minPrice = parts?.[0];
    const maxPrice = parts?.[1];

    metaData &&
      setPrice({
        minPrice: minPrice ? parseInt(minPrice) : metaData.min_price,
        maxPrice: maxPrice ? parseInt(maxPrice) : metaData.max_price,
      });
  }, [metaData]);

  const handleClickSaveSearch = () => {
    const body = {
      searched_datas: locations
        ? [
            ...searchedData,
            {
              parameter: "location",
              label: "location",
              value: locations,
            },
          ]
        : searchedData,
    };
    dispatch(fetchSaveSearch(body, token));
  };

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: locations,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
    }
    slug === categ
      ? navigate(
          `/search-result?genre=${categ}&type=${type}&key=${keyword}&page=1${
            postBy && postBy !== undefined ? `&posted_by=${postBy}` : ""
          }`
        )
      : navigate(
          `/search-result?slug=${slug}&genre=${categ}&type=${type}&key=${keyword}&page=1
        ${postBy && postBy !== undefined ? `&posted_by=${postBy}` : ""}`
        );
  };

  const handleApplyFilter = async () => {
    localStorage.setItem("search-data", JSON.stringify(filterData));
    scrollToTop();
    dispatch(
      fetchGetFilteredAds(categ, keyword, type, 1, postBy, filterData, token)
    );

    slug === categ
      ? navigate(
          `/search-result?genre=${categ}&type=${type}&key=${keyword}&page=1${
            postBy && postBy !== undefined ? `&posted_by=${postBy}` : ""
          }`
        )
      : navigate(
          `/search-result?slug=${slug}&genre=${categ}&type=${type}&key=${keyword}&page=1
          ${postBy && postBy !== undefined ? `&posted_by=${postBy}` : ""}`
        );
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddtoWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  useEffect(() => {
    if (parsedData) {
    } else {
      setLocation(locations);

      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: locations,
        },
      ];
      latitude !== "" &&
        longitude !== "" &&
        localStorage.setItem("search-data", JSON.stringify(filter));
      setFilterData(parsedData ? parsedData : []);
    }
  }, [locations, latitude, longitude]);

  useEffect(() => {
    dispatch(fetchGetAllCategories());
    parsedData
      ? dispatch(
          fetchGetFilteredAds(
            category,
            keyword,
            type1,
            pg,
            postBy,
            parsedData,
            token
          )
        )
      : dispatch(
          fetchGetFilteredAds(category, keyword, type1, pg, postBy, [], token)
        );
    setType(type1);
    category && setCateg(category);
    category && setSlug(category);
    key && setKeyword(key);
    pg && setPage(Number(pg));
    scrollToTop();
  }, [type1, category, key, pg, postBy]);

  const onchangeSearchHandler = (e) => {
    setKeyword(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    (category === "jobs" || slugs === "jobs") && setToggle(true);
  }, [category, slugs]);

  useEffect(() => {
    dispatch(
      fetchGetFilteredAds(
        category,
        keyword,
        type,
        page,
        postBy,
        filterData,
        token
      )
    );
  }, [type, keyword, page]);

  useEffect(() => {
    const data = filterData.filter((elm) => elm.label === "price");

    if (data.length) {
      const data = filterData.filter((elm) => elm.label !== "price");
      setFilterData([
        ...data,
        {
          label: "price",
          value: `${price.minPrice}__${price.maxPrice}`,
        },
      ]);
    } else {
      setFilterData([
        ...filterData,
        {
          label: "price",
          value: `${price.minPrice}__${price.maxPrice}`,
        },
      ]);
    }
  }, [price.minPrice, price.maxPrice]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    setTimeout(() => {
      message &&
        dispatch(
          fetchGetFilteredAds(
            category,
            keyword,
            type,
            page,
            postBy,
            filterData,
            token
          )
        );
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      errMsg &&
        dispatch(
          fetchGetFilteredAds(
            category,
            keyword,
            type,
            page,
            postBy,
            filterData,
            token
          )
        );
    }, 100);
  }, [errMsg]);

  useEffect(() => {
    saveMsg && toast.success(saveMsg);
  }, [saveMsg]);

  useEffect(() => {
    saveErrMsg && toast.error(saveErrMsg);
  }, [saveErrMsg]);

  useEffect(() => {
    dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeAdsData());
    };
  }, []);

  if (loading || adsLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 pb-5 relative">
      {showFilter || showCategories || showLocation ? (
        <div
          className="w-full h-full bg-black opacity-10 absolute top-0"
          onClick={() => {
            setShowFilter(false);
            setShowCategories(false);
            setShowLocation(false);
          }}
        >
          {" "}
        </div>
      ) : null}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setLocation={setLocations}
          location={locations}
        />
      )}
      <div
        className={` bg-white fixed lg:hidden left-0 top-0 pb-10 h-full w-11/12 md:w-2/3 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
          showFilter ? "-translate-x-0" : "-translate-x-full"
        }`}
      >
        <Filter
          type={type}
          setType={setType}
          setLocation={setLocation}
          setFilterData={setFilterData}
          filterData={filterData}
          setPrice={setPrice}
          price={price}
          setCateg={setCateg}
          categ={categ}
          handleApplyFilter={handleApplyFilter}
          location={location}
          onchangeSearchHandle={onchangeSearchHandler}
          searchValue={keyword}
          setSlug={setSlug}
          slugs={slugs}
          category={category}
          setShowFilter={setShowFilter}
        />
      </div>
      <Categories
        showCategories={showCategories}
        setShowCategories={setShowCategories}
      />

      <Head
        setShowCategories={setShowCategories}
        color={
          category === "cars" || slugs === "cars"
            ? "#BE3344"
            : category === "jobs" || slugs === "jobs"
            ? "#0388B7"
            : category === "real-estate" || slug === "real-estate"
            ? "#752187"
            : "#2E8B4A"
        }
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
        keyword={keyword}
      />
      <div className="bg-gray-100 py-10 mt-7">
        {/* <TopAdvertisement className="w-11/12 xl:w-3/4 mx-auto h-32" /> */}
      </div>

      <div className="w-full h-full grid grid-cols-12 gap-x-5 bg-white">
        <div className="hidden lg:block lg:col-span-4 xl:col-span-3 w-full border-r">
          <Filter
            type={type}
            setType={setType}
            setLocation={setLocation}
            setFilterData={setFilterData}
            filterData={filterData}
            setPrice={setPrice}
            price={price}
            setCateg={setCateg}
            categ={categ}
            handleApplyFilter={handleApplyFilter}
            location={location}
            onchangeSearchHandle={onchangeSearchHandler}
            searchValue={keyword}
            setSlug={setSlug}
            slugs={slugs}
            category={category}
            setShowFilter={setShowFilter}
          />
        </div>

        {/* right side */}
        <div className="col-start-1 lg:col-start-5 col-end-13 xl:col-start-4 xl:col-end-11 w-full p-5">
          {/* breadcrum */}
          <div className="w-full items-center gap-x-3 flex text-sm">
            <p
              className="text-gray-400 cursor-pointer hover:underline"
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <MdKeyboardArrowRight className="text-gray-400" />
            <p className="text-gray-400 cursor-pointer hover:underline">
              {category}
            </p>
          </div>

          <div className="w-full flex items-center justify-between">
            <h6 className="font-semibold text-lg mt-3">
              {totalData} Result on {moment().format("LL")}
            </h6>

            <div className="flex items-center gap-x-5">
              <div
                className={`w-fit p-2 rounded-md border cursor-pointer ${
                  category === "jobs" || slugs === "jobs" ? "hidden" : "block"
                }  ${
                  !toggle
                    ? category === "cars"
                      ? "bg-carPrimary text-white"
                      : category === "jobs"
                      ? "bg-jobPrimary text-white"
                      : "bg-primary text-white"
                    : "text-gray-400"
                }`}
                onClick={() => setToggle(false)}
              >
                <BsFillGridFill className="text-2xl cursor-pointer" />
              </div>

              <div
                className={`w-fit hidden md:block p-2 rounded-md border cursor-pointer ${
                  toggle
                    ? category === "cars" || slugs === "cars"
                      ? "bg-carPrimary text-white"
                      : category === "jobs" || slugs === "jobs"
                      ? "bg-jobPrimary text-white"
                      : category === "real-estate" || slug === "real-estate"
                      ? "bg-realestatePrimary text-white"
                      : "bg-primary text-white"
                    : "text-gray-400"
                }`}
                onClick={() => setToggle(true)}
              >
                <HiViewList className="text-2xl cursor-pointer" />
              </div>
            </div>
          </div>

          {saveMsg ? (
            <div
              className="w-fit border mt-5 rounded-xl py-2 px-2 gap-x-2 flex items-center cursor-pointer"
              onClick={handleClickSaveSearch}
            >
              <MdNotificationsActive className="text-gray-600 text-xl" />
              <div className="text-sm ">Saved Search</div>
            </div>
          ) : (
            <div
              className="w-fit border mt-5 rounded-xl py-2 px-2 gap-x-2 flex items-center cursor-pointer"
              onClick={handleClickSaveSearch}
            >
              <IoMdNotificationsOutline className="text-gray-600 text-xl" />
              <div className="text-sm ">Save Search</div>
            </div>
          )}

          <div
            className="items-center flex lg:hidden gap-x-3 mt-5"
            onClick={() => setShowFilter(!showFilter)}
          >
            <LuSettings2 className="text-xl" />
            <p className="text-xl font-semibold">Filter</p>
          </div>

          <div className="mt-10 w-full">
            {toggle ? (
              <ListingSearch
                category={category}
                slugs={slugs}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            ) : (
              <GridSearch
                category={category}
                slugs={slugs}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            )}
          </div>

          <div className="mt-10">
            {totalPage > 5 ? (
              <Pagination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLList;
