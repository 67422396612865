import React from "react";
import { Button, FormikControl } from "../../Component";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { fetchUpdateProfile } from "../../Redux/Profile/action";
import Cookies from "js-cookie";

const Details = ({ setShowOtpPopup }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const [address, setAddress] = useState(
    profile?.street_address ? profile.street_address : ""
  );

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",

    onPlaceSelected: (place) => {
      setAddress(place.formatted_address);
    },
    options: {
      types: ["address"],
      componentRestrictions: {
        country: "au",
      },
    },
  });

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const initialValues = {
    first_name: profile.first_name ? profile.first_name : "",
    last_name: profile.last_name ? profile.last_name : "",
    mobile: profile.user_mobile ? profile.user_mobile : "",
    address: profile.street_address ? profile.street_address : "",
    email: profile.user_email ? profile.user_email : "",
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      mobile: values.mobile,
      email: values.email,
      address: address,
    };
    dispatch(fetchUpdateProfile(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  return (
    <div className="w-full border bg-white rounded-xl mt-10">
      <p className="font-medium p-3">Profile Picture</p>
      <hr className="w-full" />
      <div className="p-3 mt-5">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full lg:flex items-center gap-x-5 justify-between">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    control="input"
                    name="first_name"
                    placeholder="Enter Your First Name"
                  />
                </div>

                <div className="w-full mt-7 lg:mt-0">
                  <FormikControl
                    label="Last Name"
                    control="input"
                    name="last_name"
                    placeholder="Enter Your Last Name"
                  />
                </div>
              </div>
              <div className="w-full lg:flex items-center gap-x-5 justify-between">
                <div className="w-full mt-7">
                  <FormikControl
                    label="Phone Number"
                    checked={
                      profile.mobile_verified === 0 ||
                      profile.mobile_verified === "0"
                        ? false
                        : true
                    }
                    setShowOtpPopup={setShowOtpPopup}
                    control="input"
                    name="mobile"
                    type="number"
                    placeholder="Enter Your Phone Number"
                  />
                </div>

                <div className="w-full mt-7">
                  <FormikControl
                    label="Email"
                    checked={true}
                    control="input"
                    name="email"
                    placeholder="Enter Your Email"
                    readOnly
                  />
                </div>
              </div>

              <div className="w-full mt-7">
                <label className="text-sm font-medium flex items-center justify-between">
                  Address
                </label>
                <input
                  ref={ref}
                  className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                  value={address}
                  onChange={handleAddressChange}
                  placeholder={address ? address : "Your Address"}
                />
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <Button
                  value="Save Changes"
                  type="submit"
                  className="border w-full text-sm rounded-2xl bg-primary cursor-pointer text-white font-medium py-4 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Details;
