import React, { useState } from "react";
import { classiBazarLogo, logo2 } from "../../Assets/Assest";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { scrollToTop } from "../NavigateTop";
import { BiUserCircle } from "react-icons/bi";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineMenu,
} from "react-icons/ai";
import MobileMenu from "./MobileMenu";
import Cookies from "js-cookie";
import { FaBell } from "react-icons/fa";
import Avatar from "react-avatar";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetNotificationList,
  fetchMakeNotificationSeen,
  removeNotificationData,
} from "../../Redux/Notification/action";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { fetchGetUserProfile } from "../../Redux/Profile/action";

const menu = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },

  {
    id: 2,
    name: "About Us",
    link: "/about-us",
  },

  {
    id: 3,
    name: "Campaign",
    link: "/campaign",
  },

  {
    id: 4,
    name: "Contact",
    link: "/contact-us",
  },

  // {
  //   id: 5,
  //   name: "Enquire",
  //   link: "enquire",
  // },
];

const Header = () => {
  const [page, setPage] = useState(1);
  const [showHeader, setShowHeader] = useState(false);
  const [headerShadow, setHeaderShadow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const notification = useSelector(
    (state) => state.notification.notification,
    shallowEqual
  );
  const noOfUnseen = useSelector(
    (state) => state.notification.unSeen,
    shallowEqual
  );
  const has_next_page = useSelector(
    (state) => state.notification.has_next_page,
    shallowEqual
  );

  const notificationLoading = useSelector(
    (state) => state.notification.isloading,
    shallowEqual
  );

  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const handleClickNotification = () => {
    dispatch(fetchMakeNotificationSeen(token));
    setShowNotification(!showNotification);
    setShowHeader(false);

    setTimeout(() => {
      dispatch(fetchGetNotificationList(page, token));
    }, 100);
  };

  const handleClickLogo = () => {
    navigate("/");
    scrollToTop();
  };

  const applyShadow = () => {
    if (window.scrollY >= 30) {
      setHeaderShadow(true);
    } else {
      setHeaderShadow(false);
    }
  };

  const handleClickLogout = () => {
    Cookies.remove("token");
    window.location.reload(false);
  };

  useEffect(() => {
    token && dispatch(fetchGetNotificationList(page, token));
    return () => {
      dispatch(removeNotificationData());
    };
  }, [page]);

  useEffect(() => {
    token && dispatch(fetchGetUserProfile(token));
  }, [token]);

  window.addEventListener("scroll", applyShadow);
  return (
    <div
      className={`h-16 sm:h-20 md:h-24 border border-b fixed  z-50 bg-headerColor w-full ${
        headerShadow ? "shadow-sm" : ""
      }`}
    >
      <div className="w-11/12 mx-auto gap-x-5 grid grid-cols-12 h-full content-center relative">
        {showHeader && (
          <div className="w-1/3 xl:w-1/4 border py-6 px-5 rounded-xl z-50 shadow-md absolute top-24 bg-gray-100 right-0">
            <h6 className="text-textPrimary font-bold text-lg">
              {profile && profile.first_name} {profile && profile.last_name}
            </h6>
            <p className="text-textSecondary text-sm mt-2">
              {profile && profile.user_email}
            </p>
            <hr className="mt-5" />
            <Link to="/me">
              <p
                className="text-primary mt-4 text-base xl:text-lg cursor-pointer hover:underline"
                onClick={() => setShowHeader(false)}
              >
                My Account
              </p>
            </Link>
            <p
              className="text-primary mt-3 text-base xl:text-lg cursor-pointer hover:underline"
              onClick={() => {
                handleClickLogout();
                setShowHeader(false);
              }}
            >
              Logout
            </p>
          </div>
        )}

        {/* notification popup */}
        {showNotification && (
          <div className="w-1/3 h-96 overflow-auto border rounded-xl z-50 shadow-md absolute top-24 bg-gray-100 right-0">
            <div className="w-full h-14 sticky top-0 border-b bg-white p-3 flex items-center justify-between">
              <h5 className="font-medium text-lg">Notifications</h5>
              <div
                className="w-fit rounded-full p-1 hover:bg-red-100"
                onClick={() => setShowNotification(false)}
              >
                <IoMdClose className="text-red-600 cursor-pointer text-2xl" />
              </div>
            </div>
            <div className="px-3 w-full h-full">
              {notificationLoading ? (
                <h4 className="w-full h-full flex items-center justify-center">
                  loading......
                </h4>
              ) : notification && notification.length ? (
                <>
                  {notification.map((val) => {
                    return (
                      <div
                        className="flex mt-5 gap-x-5 w-full cursor-pointer hover:bg-white overflow-hidden  border rounded-lg p-2"
                        key={val.id}
                      >
                        <div className="w-10 h-10">
                          <img
                            src={classiBazarLogo}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        </div>

                        <div className="w-full overflow-hidden">
                          <h6 className="text-base font-medium truncate">
                            {val.title}
                          </h6>
                          <p className="text-textSecondary mt-2 text-sm">
                            {val.body}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                  <div className="w-full mt-7 pb-5 flex items-center gap-x-7 justify-center">
                    {has_next_page ? (
                      <div
                        className=" bg-primary rounded-full p-5 w-fit text-white cursor-pointer"
                        onClick={() => {
                          setPage(page + 1);
                        }}
                      >
                        <AiOutlineArrowDown />
                      </div>
                    ) : null}
                    {page !== 1 ? (
                      <div
                        className=" bg-primary rounded-full p-5 w-fit text-white cursor-pointer"
                        onClick={() => {
                          setPage(page - 1);
                        }}
                      >
                        <AiOutlineArrowUp />
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <h5 className="flex justify-center items-center w-full h-full">
                  No Notification Yet
                </h5>
              )}
            </div>
          </div>
        )}

        <div className=" col-span-7 sm:col-span-4 lg:col-span-3 py-2">
          <img
            src={logo2}
            alt="logo"
            className="w-1/2 md:w-3/4 2xl:w-3/4 lg:h-9 2xl:h-9 cursor-pointer object-contain"
            onClick={handleClickLogo}
          />
        </div>
        <div className="hidden lg:block col-start-5 lg:col-start-4 col-end-10 h-full py-2">
          <div className="flex items-center lg:gap-x-5 2xl:gap-x-10 justify-center h-full">
            {menu.map((val) => {
              return (
                <NavLink
                  key={val.id}
                  to={val.link}
                  className={({ isActive, isPending }) =>
                    isActive
                      ? " text-primary font-semibold text-sm"
                      : "text-sm text-white hover:underline"
                  }
                >
                  {val.name}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className="hidden lg:block col-span-3 py-2">
          {token ? (
            <div className="w-full flex items-center gap-x-10">
              <div
                className="w-fit p-2 rounded-full tet-white cursor-pointer relative"
                onClick={handleClickNotification}
              >
                <FaBell className="text-2xl text-white" />
                {noOfUnseen ? (
                  <p className="absolute w-fit top-0 right-0 py-0.5 px-1 h-5 border text-white rounded-full text-[0.6rem] bg-primary">
                    {noOfUnseen}
                  </p>
                ) : null}
              </div>
              <div
                className="flex items-center gap-x-3 cursor-pointer"
                onClick={() => {
                  setShowNotification(false);
                  setShowHeader(!showHeader);
                }}
              >
                {profile && profile.image ? (
                  <div className="w-12 h-12">
                    <img
                      src={profile.image}
                      alt=""
                      className="w-full h-full rounded-full"
                    />{" "}
                  </div>
                ) : (
                  <Avatar
                    name={profile && profile.first_name}
                    size={40}
                    round={true}
                    color="#2E8B4A"
                  />
                )}
                <p className="text-white text-xs hidden xl:block">
                  Hi! {profile && profile.first_name}
                </p>
                <MdOutlineKeyboardArrowDown className="text-lg text-white" />
              </div>
            </div>
          ) : (
            <div className="flex items-center h-full gap-x-4 w-fit px-5 py-3 rounded-xl bg-primary">
              <BiUserCircle className="text-white text-lg" />
              <Button
                value="Login"
                className="text-white hover:underline text-sm w-fit"
                handleClick={() => navigate("/auth/sign-in")}
              />
              <p className="text-sm text-white">/</p>
              <Button
                value="Register"
                className="text-sm text-white w-fit hover:underline"
                handleClick={() => navigate("/auth/sign-up")}
              />
            </div>
          )}
        </div>

        {/* mobile device */}
        <div
          className="col-start-10 col-end-13 lg:hidden py-2 flex items-center justify-center justify-self-end cursor-pointer"
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        >
          <AiOutlineMenu className="text-white text-3xl sm:text-4xl md:text-5xl" />
        </div>
      </div>

      <MobileMenu
        menu={menu}
        mobileMenu={showMobileMenu}
        setMobileMenu={setShowMobileMenu}
      />
    </div>
  );
};

export default Header;
