import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const ReferralDashboard = () => {
  const referral = useSelector(
    (state) => state.referral.referral,
    shallowEqual
  );
  return (
    <div className="w-full border rounded-xl bg-white mt-10">
      <p className="p-5 font-medium">Login Friends </p>
      <hr className="w-full" />
      <div className="w-full p-5 grid grid-cols-1 md:grid-cols-2 gap-x-5">
        {referral && referral.length ? (
          referral.map((itm) => {
            return (
              <div className="border rounded-xl p-3 mt-5 md:mt-3" key={itm.id}>
                <div className="w-full flex items-center justify-between flex-wrap">
                  <p className="font-medium w-fit">{itm.name}</p>{" "}
                  <p
                    className={`px-3 py-0.5 text-sm border rounded-full ${
                      itm.status === "Active"
                        ? "bg-green-100 text-primary border-primary"
                        : "bg-red-100 text-carPrimary border-carPrimary"
                    }`}
                  >
                    {itm.status}
                  </p>
                </div>
                <p className="text-gray-500 text-sm mt-2">{itm.date}</p>
                <p className="text-sm mt-5">
                  Total Credits :{" "}
                  <span className="text-primary font-medium">
                    {itm.credit} Classi Coins
                  </span>
                </p>
              </div>
            );
          })
        ) : (
          <div>
            <h1>Sorry!! You haven't referral any friends.</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralDashboard;
