import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { usePlacesWidget } from "react-google-autocomplete";

const LocationPopup = ({
  setLatitude,
  setLongitude,
  setShowLocation,
  setLocation,
  location,
}) => {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",

    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
      setLatitude(place?.geometry?.location?.lat());
      setLongitude(place?.geometry?.location?.lng());

      const data = {
        location: place.formatted_address,
        latitude: place?.geometry?.location?.lat(),
        longitude: place?.geometry?.location?.lng(),
      };

      localStorage.setItem("search-location", JSON.stringify(data));

      const filter = [
        {
          label: "latitudes[]",
          value: place?.geometry?.location?.lat(),
        },
        {
          label: "longitudes[]",
          value: place?.geometry?.location?.lng(),
        },
        {
          label: "location",
          value: place.formatted_address,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));

      setTimeout(() => {
        setShowLocation(false);
      }, 100);
    },

    options: {
      types: ["address"],
      componentRestrictions: {
        country: "au",
      },
    },
  });

  const handleRemoveLocation = () => {
    localStorage.removeItem("search-location");
    localStorage.removeItem("search-data");
    window.location.reload(false);
  };

  return (
    <div className="border w-11/12 left-4 md:w-1/2 md:left-1/4 lg:w-1/3 lg:left-1/3 bg-white top-[5%] rounded-xl z-30 absolute pb-5">
      <div className="flex w-full items-center justify-between font-semibold text-lg  p-5">
        <p>Select Your Location</p>
        <div
          className="w-fit p-2 hover:rounded-md hover:bg-gray-100 cursor-pointer"
          onClick={() => setShowLocation(false)}
        >
          <AiOutlineClose />
        </div>
      </div>

      <hr className="w-full" />
      <div className="px-5 mt-5">
        <div className="text-sm">
          <label className="pb-2 font-medium flex text-sm items-center justify-between w-full">
            <div className="flex items-center gap-x-3">Search Location</div>{" "}
            <p
              className="text-red-600 text-xs cursor-pointer hover:underline"
              onClick={handleRemoveLocation}
            >
              Remove Location
            </p>
          </label>
          <input
            ref={ref}
            className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
            placeholder="Search Property location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationPopup;
