import React, { useEffect, useState } from "react";
import { LuSettings2 } from "react-icons/lu";
import { Button, Search } from "../../Component";
import { MdClose, MdKeyboardArrowRight } from "react-icons/md";
import { shallowEqual, useSelector } from "react-redux";
import { BaseUrl } from "../../Shared/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { usePlacesWidget } from "react-google-autocomplete";

const Filter = ({
  type,
  setType,
  setLocation,
  setFilterData,
  filterData,
  setPrice,
  price,
  setCateg,
  categ,
  handleApplyFilter,
  location,
  onchangeSearchHandle,
  searchValue,
  setSlug,
  slugs,
  category,
  setShowFilter,
}) => {
  const [isOpen, setIsOpen] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [secCategories, setSecCategories] = useState([]);

  const categories = useSelector(
    (state) => state.categories.categories,
    shallowEqual
  );

  const filters = useSelector((state) => state.ads.filters, shallowEqual);
  const parentCategory = useSelector(
    (state) => state.ads.searchTags,
    shallowEqual
  );
  const parentId = useSelector((state) => state.ads.parentId, shallowEqual);

  const handleClickRadio = (name, value) => {
    const data = filterData.filter((elm) => elm.label === name);

    if (data.length) {
      const data = filterData.filter((elm) => elm.label !== name);
      setFilterData([
        ...data,
        {
          label: name,
          value: value,
        },
      ]);
    } else {
      setFilterData([
        ...filterData,
        {
          label: name,
          value: value,
        },
      ]);
    }
  };

  const handleOpen = (item) => {
    setIsOpen((prevState) => ({
      [item]: !prevState[item],
    }));

    if (show === item) {
      return setShow(null);
    }
    setShow(item);
  };

  const handleClickCategory = async (id) => {
    // need to use parent id
    setLoading(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/post_categories?parent_id=${id}`
      );
      setSecCategories(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (parentId) {
      handleClickCategory(parentId);
    }
  }, [parentId]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",

    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);

      const data2 = {
        location: place.formatted_address,
        latitude: place?.geometry?.location?.lat(),
        longitude: place?.geometry?.location?.lng(),
      };

      localStorage.setItem("search-location", JSON.stringify(data2));

      const data = filterData.filter((elm) => elm.label === "location");

      if (data.length) {
        const data = filterData.filter(
          (elm) =>
            elm.label !== "location" &&
            elm.label !== "latitudes[]" &&
            elm.label !== "longitudes[]"
        );

        setFilterData([
          ...data,
          {
            label: "latitudes[]",
            value: place?.geometry?.location?.lat(),
          },
          {
            label: "longitudes[]",
            value: place?.geometry?.location?.lng(),
          },
          {
            label: "location",
            value: place.formatted_address,
          },
        ]);
      } else {
        setFilterData([
          ...filterData,
          {
            label: "latitudes[]",
            value: place?.geometry?.location?.lat(),
          },
          {
            label: "longitudes[]",
            value: place?.geometry?.location?.lng(),
          },
          {
            label: "location",
            value: place.formatted_address,
          },
        ]);
      }
    },

    options: {
      types: ["address"],
      componentRestrictions: {
        country: "au",
      },
    },
  });

  return (
    <div className="w-full h-full">
      {/* wanted and offering */}
      <div className="flex items-center justify-between p-5">
        <div className="items-center flex gap-x-3">
          <LuSettings2 className="text-xl" />
          <p className="text-xl font-semibold">Filter</p>
        </div>

        <div
          className="w-fit p-3 block lg:hidden border rounded-xl bg-gray-100"
          onClick={() => setShowFilter(false)}
        >
          <MdClose className="text-xl" />
        </div>
        <div
          className="w-fit hidden h-12 mt-1 rounded-full lg:flex items-center gap-x-5 py-1 px-2"
          style={{ background: "#ECEDEE" }}
        >
          <div
            className={`${
              type === "offering" ? "bg-white px-4 font-medium" : "pl-4"
            } py-1.5 rounded-full text-sm cursor-pointer`}
            onClick={() => setType("offering")}
          >
            <p>Offering</p>
          </div>
          <div
            className={`${
              type === "wanted" ? "bg-white px-4 font-medium" : "px-2"
            } py-1.5 rounded-full text-sm cursor-pointer`}
            onClick={() => setType("wanted")}
          >
            <p>Wanted</p>
          </div>
        </div>
      </div>
      <hr className="w-full" />

      <div className="p-5 w-full block lg:hidden">
        <div
          className="w-fit border h-12 mt-1 rounded-full flex items-center gap-x-5 py-1 px-2"
          style={{ background: "#ECEDEE" }}
        >
          <div
            className={`${
              type === "offering" ? "bg-white px-4 font-medium" : "pl-4"
            } py-1.5 rounded-full text-sm cursor-pointer`}
            onClick={() => setType("offering")}
          >
            <p>Offering</p>
          </div>
          <div
            className={`${
              type === "wanted" ? "bg-white px-4 font-medium" : "px-2"
            } py-1.5 rounded-full text-sm cursor-pointer`}
            onClick={() => setType("wanted")}
          >
            <p>Wanted</p>
          </div>
        </div>
      </div>

      <hr className="w-full block lg:hidden" />

      {/* search */}
      <div className="p-5 mt-2">
        <p className="font-medium mb-4">Search</p>
        <Search
          placeholder="Search Keyword"
          onchangeSearchHandler={onchangeSearchHandle}
          searchValue={searchValue}
          category={categ}
          slugs={slugs}
        />
      </div>
      <hr className="w-full" />

      {/* location */}
      <div className="p-5 mt-2">
        <p className="font-medium mb-4">Location</p>
        <div className="text-sm">
          <input
            ref={ref}
            className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
            placeholder={location ? location : "Search by location"}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
      </div>
      <hr className="w-full" />

      {/* category */}
      <div className="w-full p-5 mt-2">
        <p className="font-medium mb-5">Category</p>

        {!parentId ? (
          categories.map((val, i) => {
            return (
              <div className="w-full" key={i}>
                <div
                  className={`${
                    show === val.cat_id ||
                    categ === val.slug ||
                    slugs === val.slug
                      ? "bg-primary bg-opacity-20"
                      : ""
                  }
              w-full flex items-center justify-between mt-3 hover:bg-gray-100 px-5 py-2 cursor-pointer rounded-md`}
                  key={i}
                  onClick={() => {
                    handleOpen(val.cat_id);
                    handleClickCategory(val.cat_id);
                    setCateg(val.slug);
                    setSlug(val.slug);
                  }}
                >
                  <div className="flex items-center gap-x-5">
                    <img
                      src={val.cat_image}
                      alt=""
                      className="w-8 h-8 object-contain"
                    />
                    <p className="text-base">{val.cat_name}</p>
                  </div>
                  <MdKeyboardArrowRight
                    className={`arrow transition ease-in-out duration-500  delay-100 ${
                      !isOpen[val.cat_id] ? "close" : " open"
                    }`}
                  />
                </div>

                <div className="w-10/12 ml-10">
                  {loading ? (
                    <h5>loading....</h5>
                  ) : secCategories && secCategories.length ? (
                    secCategories.map((itm, i) => {
                      return (
                        <div
                          className={`${
                            show === val.cat_id
                              ? "w-full border flex items-center justify-between mt-3 hover:bg-gray-100 px-5 py-2 cursor-pointer rounded-md"
                              : "hidden"
                          } ${
                            categ === itm.slug || category === itm.slug
                              ? "bg-primary bg-opacity-20"
                              : ""
                          }`}
                          onClick={() => setCateg(itm.slug)}
                          key={i}
                        >
                          <div className="flex items-center gap-x-5">
                            <img
                              src={itm.cat_image}
                              alt=""
                              className="w-5 h-5 object-contain"
                            />
                            <p className="text-sm">{itm.cat_name}</p>
                          </div>
                        </div>
                      );
                    })
                  ) : null}
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-full">
            <div
              className="bg-primary bg-opacity-20 w-full flex items-center justify-between mt-3 hover:bg-gray-100 px-5 py-2 cursor-pointer rounded-md"
              onClick={() => {
                // handleClickCategory(val.cat_id);
                setCateg(parentCategory.value);
                setSlug(parentCategory.value);
              }}
            >
              <p className="text-base">{parentCategory.label}</p>
            </div>

            <div className="w-10/12 ml-10">
              {secCategories && secCategories.length
                ? secCategories.map((itm, i) => {
                    return (
                      <div
                        className={` "w-full border flex items-center justify-between mt-3 hover:bg-gray-100 px-5 py-2 cursor-pointer rounded-md"
                          ${
                            categ === itm.slug || category === itm.slug
                              ? "bg-primary bg-opacity-20"
                              : ""
                          }`}
                        onClick={() => {
                          setCateg(itm.slug);
                          setSlug(parentCategory.value);
                        }}
                        key={i}
                      >
                        <div className="flex items-center gap-x-5">
                          <img
                            src={itm.cat_image}
                            alt=""
                            className="w-5 h-5 object-contain"
                          />
                          <p className="text-sm">{itm.cat_name}</p>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        )}
      </div>
      <hr className="w-full" />

      {/* price */}
      <div className="w-full p-5 mt-2">
        <p className="font-medium mb-5">Price Range</p>
        <div className="w-full flex items-center gap-x-3">
          <div className="flex items-center w-full gap-x-1 border-gray-300 rounded-lg border py-1.5 px-3">
            <p className="text-lg font-semibold">$</p>
            <input
              type="number"
              min="0"
              className=" w-full text-sm p-2 outline-none"
              placeholder="Min Value"
              onChange={(e) =>
                setPrice({
                  minPrice: e.target.value,
                  maxPrice: price?.maxPrice,
                })
              }
              value={price.minPrice}
            />
          </div>

          <span> -</span>
          <div className="flex items-center w-full gap-x-1 border-gray-300 rounded-lg border px-3 py-1.5">
            <p className="text-lg font-semibold">$</p>
            <input
              type="number"
              min="0"
              className=" w-full text-sm p-2 outline-none"
              placeholder="Max Value"
              onChange={(e) =>
                setPrice({
                  minPrice: price?.minPrice,
                  maxPrice: e.target.value,
                })
              }
              value={price.maxPrice}
            />
          </div>
        </div>
      </div>
      <hr className="w-full" />

      {/* filters */}
      {filters &&
        filters.map((val, i) => {
          return val?.childs?.length &&
            val?.attr?.name !== "type" &&
            val?.attr?.label !== "Years" ? (
            <div key={i}>
              <div className="w-full p-5 mt-2">
                <p className="font-medium mb-5">{val?.attr?.label}</p>
                {val?.childs?.map((itm, i) => {
                  return (
                    <div
                      className="flex items-center gap-x-3 w-full mt-1 p-3"
                      key={i}
                    >
                      <input
                        type="radio"
                        className="w-4 h-4"
                        name={val?.attr?.label}
                        value={itm?.value}
                        checked={
                          filterData &&
                          filterData.find(
                            (elem) => elem.label === val?.attr?.name
                          )?.value === itm?.value
                        }
                        onChange={() =>
                          handleClickRadio(val?.attr?.name, itm?.value)
                        }
                      />
                      <p>{itm.label}</p>
                    </div>
                  );
                })}
              </div>
              <hr className="w-full" />
            </div>
          ) : null;
        })}
      <hr className="w-full" />

      <div className="w-full flex items-center gap-x-5 p-5 mt-2">
        <Button
          value="Apply"
          handleClick={handleApplyFilter}
          className={`${
            categ === "cars" || slugs === "cars"
              ? "bg-carPrimary"
              : category === "motorcycles-scooters" ||
                slugs === "motorcycles-scooters"
              ? " bg-carPrimary"
              : categ === "jobs" || slugs === "jobs"
              ? "bg-jobPrimary"
              : "bg-primary"
          } w-full text-white rounded-xl py-3 text-center`}
        />
      </div>
    </div>
  );
};

export default Filter;
