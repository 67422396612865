import React from "react";
import { useEffect } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MessageSent from "./MessageSent";
import MessageReceived from "./MessageReceived";
import AgentEnquiry from "./AgentEnquiry";

const OfferMessages = () => {
  const [toggle, setToggle] = useState("");
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let status = params.get("status");

  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  useEffect(() => {
    setToggle(status === null ? "" : status);
  }, [status]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Offer/Message</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      {/* header */}
      <div className="w-full flex items-center gap-x-5 mt-4 border-b">
        <div
          className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
            status === null ? "border-b-2 border-primary" : ""
          }`}
          onClick={() => {
            setToggle("");
            navigate(`/me/offers`);
          }}
        >
          <p className={`${status === null ? "" : "text-gray-500"}`}>
            Offer Received
          </p>
        </div>

        <div
          className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
            status === "sent" ? "border-b-2 border-primary" : ""
          }`}
          onClick={() => {
            setToggle("sent");
            navigate(`/me/offers?status=sent`);
          }}
        >
          <p className={`${status === "sent" ? "" : "text-gray-500"}`}>
            Offer Sent
          </p>
        </div>

        <div
          className={`flex items-center gap-x-2 lg:gap-x-5 w-full justify-center text-sm py-5 cursor-pointer ${
            status === "enquiry" ? "border-b-2 border-primary" : ""
          }`}
          onClick={() => {
            setToggle("enquiry");
            navigate(`/me/offers?status=enquiry`);
          }}
        >
          <p className={`${status === "enquiry" ? "" : "text-gray-500"}`}>
            Find an Agent Enquiry
          </p>
        </div>
      </div>

      {/* body */}

      {toggle === "sent" ? (
        <MessageSent />
      ) : toggle === "enquiry" ? (
        <AgentEnquiry />
      ) : (
        <MessageReceived />
      )}
    </div>
  );
};

export default OfferMessages;
