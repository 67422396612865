import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  saveSearch: [],
  totalData: "",
};

export const savedSearch = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SAVED_SEARCH:
      return {
        ...state,
        isloading: false,
        success: true,
        saveSearch: action.payload.saved_searches,
        totalData: action.payload.total_number,
      };
    case ActionTypes.REMOVE_SAVED_SEARCH:
      return {
        isloading: true,
        success: false,
        saveSearch: [],
        totalData: "",
      };

    default:
      return state;
  }
};
